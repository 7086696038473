export const tableApiPaging = (event, state, setState, totalCount, payloadData) =>{
	if (event){
		return new Promise((resolve, reject) => {
			const pager = event.element.querySelectorAll(".dx-pager .dx-page-sizes .dx-page-size")
			pager.forEach((el) => {
				el.addEventListener("click",function(e){
					const count = e.target?.innerText * 1
					let payload = {count}
					setState({
						...state,
						paging: {
							...state.paging,
							defaultPageSize: count
						}
					})
					return resolve({
						target: "pager",
						payload: {
							...payloadData,
							...payload,
						}
					})
				}, false);
			})

			// data buttons
			const buttons = event.element.querySelectorAll(".dx-page-indexes [class*=-button]")
			const prevButton = event.element.querySelector(".dx-prev-button")
			const nextButton = event.element.querySelector(".dx-next-button")
			const showPrev = state.paging.defaultPageSize < payloadData.page * state.paging.defaultPageSize
			const showNext = totalCount >= state.paging.defaultPageSize * payloadData.page

			prevButton && (showPrev ? prevButton.classList.remove("dx-button-disable", "pointer-events-none") : prevButton.classList.add("dx-button-disable", "pointer-events-none"))
			nextButton && (showNext ? nextButton.classList.remove("dx-button-disable", "pointer-events-none") : nextButton.classList.add("dx-button-disable", "pointer-events-none"))

			buttons.forEach((el) => {
				el.addEventListener("click",function(e){
					let payload = {page: payloadData.page}
					const prevClicked = e.target.classList.contains("dx-prev-button")
					const nextClicked = e.target.classList.contains("dx-next-button")
					if (prevClicked) payload = {page: payloadData.page - 1}
					if (nextClicked) payload = {page: payloadData.page + 1}
					return resolve({
						target: "buttons",
						payload: {
							...payloadData,
							...payload,
						}
					})
				}, false);
			})
		})
	} else {
		return "Warning: DataTable: waiting for event"
	}
}

export default tableApiPaging()