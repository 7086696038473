import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/clientConfiguration"

import { CLIENT_CONFIG_REQUESTED } from "redux/types"
import { API_POST } from "helpers/api"

function* watchGetClientConfig() {
  yield takeLatest(CLIENT_CONFIG_REQUESTED, getClientConfig)
}

function* getClientConfig(action) {
  try {
    const data = action.payload
    const res = yield call(apiGetClientConfig, data)
    yield put(actions.clientConfigSucceed(res))
  } catch (err) {
    yield put(actions.clientConfigFailed())
  }
}

const apiGetClientConfig = (data) => {
  if (Object.keys(data)?.length !== 0) {
    var formBody = []
    for (var property in data) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")

    return API_POST(
      `${process.env.REACT_APP_DISPLAY_DOMAIN}/api/clientconfiguration/save`,
      formBody,
      "application/x-www-form-urlencoded",
    )
  }

  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/clientconfigurationpaneluser/save`, data)
}

export default function* clientConfig() {
  yield fork(watchGetClientConfig)
}
