import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Cookies from "universal-cookie"

// components
import { Alert, Icon } from "components"
import { Dropdown, Whisper, Avatar } from "rsuite"
//import AdminIcon from '@rsuite/icons/Admin'

// images
import Logo from "assets/img/logo.png"

// utils
import { isDevEnv } from "utils/environmentsCheck"
import { logout } from "utils/logout"
import {useDomainChange} from "utils/useDomainChange";
import {domainChange} from "utils/domainChange";

// saga-requests
import { setDevice } from "pages/B2cDemoPage/redux/actions/DisplayDevice"
import { saveConfigurationRequested } from "pages/B2cDemoPage/redux/actions/SaveConfiguration"
import { setViewportFullSize } from "pages/B2cDemoPage/redux/actions/ViewportFullSize"

const cookies = new Cookies()

export default function MainHeader(props) {
  let { isLogged, isDemo, lpLogoFile, DisabledSaveButton, className, marketPlace, favIconFile } = props

  const dispatch = useDispatch()
  const { mutateAsync, isPending } = useDomainChange()

  const DomainId = cookies.get("associatedDomain")?.domainId
  const DomainName = cookies.get("associatedDomain")?.domainName
  const DomainType = cookies.get("associatedDomain")?.domainType
  //const DomainList = cookies.get("domainList")
  const userInfo = cookies.get("userInfo")
  let name = userInfo?.name

  // Selectors
  let getThemeConfig = useSelector((state) => state.getThemeConfig.value)
  const Device = useSelector((state) => state.DisplayDevice)
  const ViewportSize = useSelector((state) => state.ViewportFullSize.show)
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)
  const saveConfigurationResponse = useSelector((state) => state.SaveConfiguration.data)
  const saveConfigurationLoading = useSelector((state) => state.SaveConfiguration.loading)

  // States
  const [ShowSaveChanges, setShowSaveChanges] = useState(false)
  const [DisplayDevice, setDisplayDevice] = useState({ id: 1, text: "Masaüstü" }) // default 0 mobile, 1 desktop

  // Func

  const showSaveChangesAlert = () => {
    setShowSaveChanges(!ShowSaveChanges)
  }

  const saveChanges = async () => {
    if (!DomainId) alert("domain ID bulunamadı")
    let getConfig = JSON.parse(getThemeConfig)
    let ThemeConfiguration = ListConfiguration.displays.find(
      (item) => item?.styles?.borderRadius === getConfig.displaySettings?.borderRadius,
    )
    let LinkTypeConfiguration = ListConfiguration.links.find(
      (item) => item?.styles?.textDecorationLine === getConfig.displaySettings?.linkType,
    )
    let obj = {
      ThemeConfiguration: DomainType === "Corporate" ? "Standart Görünüm" : ThemeConfiguration?.label,
      LinkTypeConfiguration: DomainType === "Corporate" ? "Altı Çizgili" : LinkTypeConfiguration?.label,
      ColorConfiguration: JSON.stringify({ color: getConfig?.displaySettings?.buttonColor }),
      LinkColorConfiguration: JSON.stringify({
        linkcolor: getConfig?.displaySettings?.linkColor,
      }),
      FontConfiguration: DomainType === "Corporate" ? "Inter" : getConfig?.displaySettings?.fontFamily,
    }
    var formData = new FormData()
    for (let key in obj) {
      formData.append(key, obj[key])
    }
    if (!lpLogoFile) {
      await fetch(getConfig?.logoValues?.src)
        .then((res) => res.blob())
        .then((file) => {
          formData.append("ClientLogo", file, file.name)
        })
    } else {
      formData.append("ClientLogo", lpLogoFile, lpLogoFile.name)
    }
    if (!favIconFile) {
      await fetch(getConfig?.favIconValues?.src)
        .then((res) => res.blob())
        .then((file) => {
          formData.append("ClientFavIcon", file, file.name)
        })
    } else {
      formData.append("ClientFavIcon", favIconFile, favIconFile.name)
    }

    dispatch(saveConfigurationRequested(formData))
  }

  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = (eventKey) => {
      onClose()
    }
    return (
      <Dropdown.Menu
        ref={ref}
        className={`${className} min-w-[180px] max-w-[240px] !top-[64px] shadow-lg border border-solid border-gray-200 overflow-hidden`}
        style={{ left, top }}
        onSelect={handleSelect}
      >
        <Dropdown.Item
          eventKey={0}
          className={
            "pb-[12px] pt-[6px] px-[16px] cursor-default hover:bg-white hover:text-gray-800 font-medium text-[#1D2939] truncate"
          }
        >
          Hoşgeldin, {name}
        </Dropdown.Item>
        <hr />
        <Dropdown.Item
          eventKey={1}
          className={`${!isDevEnv ? "hidden " : ""} py-[10px] px-[16px]`}
        >
          <Link
            to="/my-account"
            className={"block"}
          >
            Profili Gör
          </Link>
        </Dropdown.Item>
        {DomainType === "Corporate" && (
          <Dropdown.Item
            eventKey={1}
            className={`py-[10px] px-[16px]`}
          >
            <Link
              to={`https://${DomainName}`}
              target="_blank"
              className={"flex justify-between items-center"}
            >
              Seyahat paneline git
              <Icon
                name={"external-link"}
                size={16}
                className={"!bg-[#2E90FA]"}
              />
            </Link>
          </Dropdown.Item>
        )}

        {/* <Dropdown.Item
          eventKey={1}
          className={"py-2.5 px-4"}
        >
          <Link to="/my-account">Ayarlar</Link>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey={1}
          className={"py-2.5 px-4"}
        >
          <Link to="/my-account">Destek</Link>
        </Dropdown.Item> */}
        <hr />
        <Dropdown.Item
          eventKey={2}
          onClick={() => logout()}
          className={"py-[10px] pb-[6px] px-[16px]"}
        >
          Çıkış Yap
        </Dropdown.Item>
        {/*{DomainList && DomainList?.length > 1 && (
          <>
            <Dropdown.Item
              eventKey={1}
              className={`bg-gray-100 p-0 cursor-default hover:bg-gray-100 hover:text-gray-600`}
            >
              <span className="block pt-3 pb-1 px-4 mb-1 text-[#1D2939]">Domainlerim</span>
              <div className="accounts flex flex-col">
                {DomainList.map(domain => {
                  return (
                    <Link
                      to={"/"}
                      className={`${domain?.isLoggedDomain ? "bg-white text-[var(--rs-menuitem-active-text)] hover:bg-white" : "bg-gray-50 hover:bg-gray-50 hover:text-gray-600 hover:bg-[var(--rs-menuitem-active-bg)] hover:text-[var(--rs-menuitem-active-text)]"} p-0 cursor-pointer px-4 py-2 truncate`}
                      onClick={e => handleDomainChange(e, domain)}
                      key={domain.domainId}
                    >
                      <Icon
                        className="mr-1"
                        color="currentColor"
                        name="arrow-up-right-from-square"
                        size={12}
                      />
                      <span title={domain?.domainName}>{domain?.domainName}</span>
                    </Link>
                  )
                })}
              </div>
            </Dropdown.Item>
          </>
        )}*/}
      </Dropdown.Menu>
    )
  }

  const handleDomainChange = async (event, domain) => {
    if (domain?.isLoggedDomain) {
      event.stopPropagation()
      return false
    }
    await mutateAsync(domain)
      .then((e) => {
        if (e?.data?.errors?.[0]?.message) {
          return alert(e?.data?.errors?.[0]?.message)
        }
        else if (e?.data?.success) {
          return domainChange(dispatch)
        }
      })
      .catch(() => {
        alert("Bir sorun oluştu")
      })
  }

  // Effects
  useEffect(() => {
    if (Device.id !== DisplayDevice.id) {
      dispatch(setDevice(DisplayDevice))
    }
  }, [DisplayDevice, Device, dispatch])

  useEffect(() => {
    if (saveConfigurationResponse && saveConfigurationResponse?.success) {
      setShowSaveChanges(false)
    }
  }, [saveConfigurationResponse])

  return (
    <>
      <header
        className={`header${
          ViewportSize ? "-hidden" : ""
        } sticky table w-full top-0 left-0 h-[72px] border-b border-b-gray-200 bg-white py-[14px] px-4 lg:px-0 z-50 overflow-auto${
          ` ` + className
        }`}
      >
        <div className={isLogged ? "w-full px-10" : "container"}>
          <div className="flex items-center justify-between">
            <div className="logo-area flex">
              <Link to="/">
                <figure>
                  <img
                    src={Logo}
                    alt="Admin Control Panel Logo"
                  />
                </figure>
              </Link>
              {marketPlace && (
                <div className="flex items-center justify-center gap-3 ml-3 border-l-[1px] border-l-gray-300 pl-3">
                  <Icon
                    color="currentColor"
                    name="marketplace"
                  />
                  <span className="font-bold text-gray-800 text-xl">Mağaza</span>
                </div>
              )}
            </div>

            {isLogged && !isDemo && (
              <div className="button-area flex flex-row relative">
                <button className="btn border border-gray-300 px-3.5 font-normal hover:bg-slate-100 bg-white text-gray-500">
                  <Icon
                    name="life-buoy"
                    size="20"
                    color="#667085"
                  />
                  <span className="text-base pointer-events-none">Yardım</span>
                  <div className="icon-area flex items-center justify-center">
                    {" "}
                    {/*rotate-180 transition duration-300 ease-linear*/}
                    <Icon
                      name="chevron-down"
                      size="20"
                      color="#667085"
                    />
                  </div>
                </button>

                <Whisper
                  className={"relative"}
                  placement="bottomEnd"
                  trigger="click"
                  speaker={renderMenu}
                >
                  <button className="btn border border-gray-300 px-3.5 font-normal hover:bg-slate-100 bg-white text-gray-500 ml-4 overflow-hidden max-w-[196px] flex justify-start items-center">
                    <div>
                      <Avatar
                        circle
                        size="sm"
                        className="w-[24px] h-[24px]"
                        // If you want to use image avatar, un-comment this
                        // src={avatar_url}
                        // alt={avatar_alt}

                      ></Avatar>
                    </div>
                    <span className="text-base pointer-events-none truncate">{name}</span>
                    <div
                      className={`icon-area flex items-center justify-center transition duration-200 ease-linear${
                        !true ? " rotate-180" : ""
                      }`}
                    >
                      {" "}
                      {/**/}
                      <Icon
                        name={"chevron-down"}
                        size="20"
                        color="#667085"
                      />
                    </div>
                  </button>
                </Whisper>
              </div>
            )}

            {isDemo && (
              <>
                <div className="view-chooser flex items-stretch text-sm text-gray-400 font-medium py-2 px-7 border border-gray-200 rounded-lg">
                  {[
                    { text: "Mobil", icon: "smartphone" },
                    { text: "Masaüstü", icon: "monitor" },
                  ].map((v, k) => {
                    // Eğer DomainType "Corporate" ise ve şu anki eleman "Mobil" ise, render etme
                    if (DomainType === "Corporate" && v.text === "Mobil") {
                      return null
                    }

                    return (
                      <div
                        key={k}
                        onClick={() => setDisplayDevice({ id: k, name: v.text })}
                        className={`mobile-display flex items-center cursor-pointer border-l border-gray-200 first:border-l-0 pl-5 ml-5 transition first:pl-0 first:ml-0${
                          Device.id === k ? " text-blue-500" : ""
                        }`}
                      >
                        <Icon
                          className="mr-2"
                          name={v.icon}
                          size="16"
                          color={Device.id === k ? "#2E90FA" : "#98A2B3"}
                        />{" "}
                        {/*2E90FA*/}
                        <p>{v.text}</p>
                      </div>
                    )
                  })}
                </div>

                <div className="relative flex items-center">
                  <button
                    onClick={() => dispatch(setViewportFullSize(true))}
                    className="btn sm outline !p-2"
                  >
                    <Icon
                      name="maximize-2"
                      size="24"
                      color="#667085"
                    />
                  </button>

                  <button
                    onClick={showSaveChangesAlert}
                    disabled={DisabledSaveButton || saveConfigurationLoading}
                    className={`${DisabledSaveButton || saveConfigurationLoading ? "disabled " : ""}btn sm ml-5`}
                  >
                    <span className="text-base pointer-events-none">Kaydet</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </header>

      {ShowSaveChanges && (
        <Alert
          iconName=""
          title="Rezervasyon sayfanızı yayınlamak istediğinize emin misiniz?"
          description="Sayfanızı yayınladığınızda müşterilerinize görünür duruma gelir ve rezervasyon almaya başlayabilirsiniz. Rezervasyon almayı durdurmak isterseniz dilediğiniz zaman yayınını durdurabilirsiniz."
          rejectButtonOnClick={showSaveChangesAlert}
          approveButtonOnClick={saveChanges}
          approveButtonText={"Evet, yayınla"}
          loading={saveConfigurationLoading}
        />
      )}
    </>
  )
}

MainHeader.propTypes = {
  className: PropTypes.string,
}
MainHeader.defaultProps = {
  className: "",
}
