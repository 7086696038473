import { useMutation } from "@tanstack/react-query"
import { API_POST} from "helpers/api"

export const useDomainChange = () => {
	const mutation = useMutation({
		mutationFn: (domain) =>
			API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/change`, {
				id: domain?.domainId
			})
	})

	return mutation
}