import React from "react";
import { Quill } from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import ImageUploader from 'quill-image-uploader';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import {API_POST} from "helpers/api";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  // @ts-ignore
  this.quill.history.undo();
}
function redoChange() {
  // @ts-ignore
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);
Quill.register("modules/htmlEditButton", htmlEditButton);
Quill.register("modules/imageUploader", ImageUploader);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor


export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
  htmlEditButton: {
    debug: false, // logging, default:false
    msg: "Kaynak düzenle", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Tamam", // Text to display in the OK button, default: Ok,
    cancelText: "İptal", // Text to display in the cancel button, default: Cancel
    buttonHTML: "Kaynak", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: true, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: null, // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {} // The default mod
  },
  imageUploader: {
    upload: file => {
      const formData = new FormData();
      formData.append('File', file);
      return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/medialibrary/save`, formData).then(({data}) => {
				return data?.result?.url
			})
    }
  }

};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code"
];


// Quill Toolbar component
export const QuillToolbar = ({className}) => (
  <div id="toolbar" className={className}>
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button title="Kalın" className="ql-bold" />
      <button title="Eğik" className="ql-italic" />
      <button title="Altı Çizgili" className="ql-underline" />
      <button title="Orası Çizgili" className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button title="Liste" className="ql-list" value="ordered" />
      <button title="Liste" className="ql-list" value="bullet" />
      <button title="Azalt" className="ql-indent" value="-1" />
      <button title="Arttır" className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button title="Script" className="ql-script" value="super" />
      <button title="Script" className="ql-script" value="sub" />
      <button title="Bloknot" className="ql-blockquote" />
    </span>
    <span className="ql-formats">
      <select title="Hizalama" className="ql-align" />
      <select title="Renk" className="ql-color" />
      <select title="Arka Plan" className="ql-background" />
    </span>
    <span className="ql-formats">
      <button title="Link" className="ql-link" />
      <button title="Görsel" className="ql-image" />
      <button title="Video" className="ql-video" />
    </span>
    <span className="ql-formats">
      <button title="Formül" className="ql-formula" />
      <button title="Kod" className="ql-code" />
      <button title="Temizle" className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button title="Geri Al" className="ql-undo">
        <CustomUndo />
      </button>
      <button title="İleri Al" className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
