import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { BookingConfirmationFilters } from "../../constants"

// components
import MainHeader from "components/Headers/MainHeader"
import MyReservationsApproveModal from "components/MyReservationsApproveModal"
import { LoadBar, Icon, BorderBox } from "components"

// import Swiper core and required modules
import { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Moment.JS
import moment from "moment"

// utils
import CurrencyFormat from "../../utils/currencyFormat"

// Requests
import { myReservationsDetailRequested } from "../../redux/actions/myReservationsDetail"
import { myReservationsApproveRequested } from "../../redux/actions/myReservationsApprove"

// Moment.JS localization
moment.locale("tr")

// Cookies

const ReservationDetail = (props) => {
  let { isLogged, AssociatedDomains } = props

  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const DomainType = AssociatedDomains?.domainType

  const myReservationsDetail = useSelector((state) => state.myReservationsDetail.list)
  const myReservationsDetailLoading = useSelector((state) => state.myReservationsDetail.loading)

  const [ReservationDetails, setReservationDetails] = useState([])
  const [EditorData, setEditorData] = useState()
  const [ConfirmReservationModal, setConfirmReservationModal] = useState(false)
  const [DynamicClasses, setDynamicClasses] = useState({
    confirmation: [],
    confirmationText: "",
    paymentStatusText: "",
  })
  const [ConfirmStatusGroup] = useState(BookingConfirmationFilters)

  const approveReservation = () => {
    setConfirmReservationModal(true)
  }
  const approveReservationModal = () => {
    dispatch(
      myReservationsApproveRequested({
        ProductType: 1,
        BookingCode: id,
        IsReject: false,
        RejectReason: 0,
        ApproverDescription: EditorData,
      }),
    )
    setConfirmReservationModal(false)
  }

  useEffect(() => {
    dispatch(
      myReservationsDetailRequested({
        ProductType: 1,
        BookingCode: id,
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (myReservationsDetail) {
      setReservationDetails(myReservationsDetail)

      // cf = confirmation
      let cfColors,
        cfStatus = myReservationsDetail?.confirmationStatus

      cfColors = ConfirmStatusGroup.map((a) => {
        if (a.filterStatus.includes(cfStatus)) {
          return [a.textColor, a.bgColor]
        }
        return []
      })

      let filteredColors = cfColors.filter((el) => el !== undefined)
      let joinFilteredColors = filteredColors.join(" ")

      setDynamicClasses({
        ...DynamicClasses,
        confirmation: [joinFilteredColors.replace(",", " ")],
        confirmationText: joinFilteredColors.split(",")[0],
        paymentStatusText: joinFilteredColors.split(",").pop(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myReservationsDetail])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <div
            onClick={() => {
              navigate(-1)
            }}
            className="go-back inline-flex items-center cursor-pointer"
          >
            <Icon
              className="mr-2"
              name="arrow-left"
              size="20"
              color="#667085"
            />
            <span className="text-base text-gray-500">Geri Dön</span>
          </div>
          <div className="page-top-area flex items-center justify-between my-6">
            <div className="page-title">
              <h3 className="text-3xl font-semibold">Rezervasyon Detayı</h3>
              {myReservationsDetailLoading ||
                (DomainType === "B2C" && (
                  <div className="flex items-center mt-10 mb-3 ml-4 font-semibold">
                    <div className="flex p-3 mr-3 bg-blue-50 rounded-lg">
                      <Icon
                        className=""
                        name="home"
                        size="20"
                        color="#2E90FA"
                      />
                    </div>
                    <h3 className="text-blue-500 text-2xl">{ReservationDetails?.productName}</h3>
                  </div>
                ))}
            </div>
            {/*{(myReservationsDetailLoading || (myReservationsDetail && ReservationDetails?.isNotificationSent)) && ( // simdilik kapatilmasi istenmistir
              <button
                onClick={approveReservation}
                className={`btn border border-gray-300 px-3.5 font-normal text-white ${
                  myReservationsDetailLoading ? "pointer-events-none" : ""
                }`}
              >
                {myReservationsDetailLoading && <LoadBar />}
                {!myReservationsDetailLoading && ReservationDetails?.confirmationStatus && (
                  <>
                    <Icon
                      name="check-square"
                      size="20"
                      color="#fff"
                    />
                    <span className="text-base pointer-events-none">Konfirmasyon Onayla</span>
                  </>
                )}
              </button>
            )}*/}
          </div>

          <div className="reservation-detail-area">
            {myReservationsDetail === null && (
              <BorderBox className="text-lg p-8">
                <p className="alert text-error-500">{id} Numaralı Rezervasyon bulunamadı!</p>
              </BorderBox>
            )}

            {(myReservationsDetailLoading || myReservationsDetail) && (
              <>
                <BorderBox className="!flex-row items-center p-8">
                  <div>
                    {!myReservationsDetailLoading && (
                      <>
                        <h2 className="text-gray-800 text-lg font-semibold mb-2">Rezervasyon Numarası</h2>
                        <h1 className="text-blue-500 text-2xl font-semibold">{ReservationDetails?.bookingCode}</h1>
                      </>
                    )}
                    {myReservationsDetailLoading && (
                      <div className="animate-pulse space-y-3 w-[150px]">
                        <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-slate-200 rounded col-span-2" />
                          <div className="h-2 bg-slate-200 rounded col-span-1" />
                        </div>
                        <div className="h-2 bg-slate-200 rounded" />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center badge-area text-sm font-weight-medium ml-auto select-none">
                    <span className={`${DynamicClasses.confirmation} inline-flex items-center rounded-2xl px-3 py-1 ml-2`}>
                      {myReservationsDetailLoading && <LoadBar />}
                      {!myReservationsDetailLoading && ReservationDetails?.confirmationStatusString && (
                        <span>{ReservationDetails.confirmationStatusString}</span>
                      )}
                    </span>
                  </div>
                </BorderBox>
                <BorderBox className="!flex-row items-center p-8 mt-4">
                  {myReservationsDetailLoading && (
                    <div className="animate-pulse space-y-3 w-[150px]">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-200 rounded col-span-2" />
                        <div className="h-2 bg-slate-200 rounded col-span-1" />
                      </div>
                      <div className="h-2 bg-slate-200 rounded" />
                    </div>
                  )}
                  {!myReservationsDetailLoading && ReservationDetails?.confirmationStatusString && (
                    <div className="flex flex-col">
                      <h3 className="text-gray-800 font-semibold">Konfirme durumu</h3>
                      <p className={`${DynamicClasses?.confirmationText} mt-2`}>{ReservationDetails.confirmationStatusString}</p>
                      <small className="block text-base text-gray-400 mt-0.5">
                        {/* {ReservationDetails?.bookingDate ? moment(ReservationDetails?.bookingDate).format("DD.MM.YYYY") : "-"} */}
                        {ReservationDetails?.checkInDate ? moment(ReservationDetails?.checkInDate).format("DD.MM.YYYY") : "-"}
                      </small>
                    </div>
                  )}
                </BorderBox>
                <div className="columns-2 mt-4">
                  <BorderBox className="text-lg p-8">
                    <h3 className="text-gray-800 font-semibold">Check-In</h3>
                    {myReservationsDetailLoading && (
                      <div className="animate-pulse space-y-3 w-[100px] mt-6 mb-4">
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="grid grid-cols-3 gap-2">
                          <div className="h-2 bg-slate-200 rounded col-span-2" />
                          <div className="h-2 bg-slate-200 rounded col-span-1" />
                        </div>
                      </div>
                    )}
                    {!myReservationsDetailLoading && ReservationDetails?.checkInDate && (
                      <p className="text-gray-500 mt-2">{moment(ReservationDetails?.checkInDate).format("DD.MM.YYYY")}</p>
                    )}
                  </BorderBox>
                  <BorderBox className="text-lg p-8">
                    <h3 className="text-gray-800 font-semibold">Check-Out</h3>
                    {myReservationsDetailLoading && (
                      <div className="animate-pulse space-y-3 w-[100px] mt-6 mb-4">
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="grid grid-cols-3 gap-2">
                          <div className="h-2 bg-slate-200 rounded col-span-2" />
                          <div className="h-2 bg-slate-200 rounded col-span-1" />
                        </div>
                      </div>
                    )}
                    {!myReservationsDetailLoading && ReservationDetails?.checkOutDate && (
                      <p className="text-gray-500 mt-2">{moment(ReservationDetails?.checkOutDate).format("DD.MM.YYYY")}</p>
                    )}
                  </BorderBox>
                </div>
                <div className="columns-2 mt-4">
                  <BorderBox className="text-lg p-8">
                    <h3 className="text-gray-800 font-semibold">Toplam Gece Sayısı</h3>
                    {myReservationsDetailLoading && (
                      <div className="animate-pulse space-y-3 w-[100px] mt-6 mb-4">
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="grid grid-cols-3 gap-2">
                          <div className="h-2 bg-slate-200 rounded col-span-2" />
                          <div className="h-2 bg-slate-200 rounded col-span-1" />
                        </div>
                      </div>
                    )}
                    {!myReservationsDetailLoading && ReservationDetails?.confirmationStatusString && (
                      <div className={`${DynamicClasses.confirmationText} mt-2`}>
                        <p className="text-lg text-gray-500">
                          {ReservationDetails?.rooms && ReservationDetails?.rooms[0]?.night} gece{" "}
                          {ReservationDetails?.rooms && ReservationDetails?.rooms[0]?.night > 1
                            ? `${ReservationDetails?.rooms[0]?.night - 1} gün`
                            : ""}
                        </p>
                        <p className="block text-base text-gray-400 mt-0.5">
                          {moment(ReservationDetails?.checkInDate).format("DD.MM.YYYY")} -{" "}
                          {moment(ReservationDetails?.checkOutDate).format("DD.MM.YYYY")}
                        </p>
                      </div>
                    )}
                  </BorderBox>
                  <BorderBox className="text-lg p-8">
                    <h3 className="text-gray-800 font-semibold">Toplam konaklama bedeli</h3>
                    {myReservationsDetailLoading && (
                      <div className="animate-pulse space-y-3 w-[100px] mt-6 mb-4">
                        <div className="h-2 bg-slate-200 rounded" />
                        <div className="grid grid-cols-3 gap-2">
                          <div className="h-2 bg-slate-200 rounded col-span-2" />
                          <div className="h-2 bg-slate-200 rounded col-span-1" />
                        </div>
                      </div>
                    )}
                    {!myReservationsDetailLoading && ReservationDetails?.totalPrice && (
                      <p className="text-gray-500 mt-2">
                        {CurrencyFormat({
                          currency: ReservationDetails.totalPrice.substring(0, 3),
                          money: ReservationDetails.totalPrice.substring(3),
                        })}
                        <small className="text-success-500 block text-base mt-0.5">Ödendi</small>
                        {/*{ReservationDetails?.paymentStatusText ? ReservationDetails.paymentStatusText : "-"}*/}
                      </p>
                    )}
                  </BorderBox>
                </div>

                <div className="mt-4">
                  {myReservationsDetailLoading && (
                    <BorderBox className="text-lg p-8">
                      <h3 className="text-gray-800 font-semibold mb-9">Rezervasyon Bilgileri</h3>
                      <table className="w-full text-left">
                        <colgroup>
                          <col width="33.33333%" />
                          <col width="33.33333%" />
                          <col width="33.33333%" />
                        </colgroup>
                        <thead className="text-sm font-weight-medium">
                          <tr>
                            <th className="pb-0.5">İsim soyisim</th>
                            <th className="pb-0.5">Kimlik Numarası</th>
                            <th className="pb-0.5">Doğum tarihi</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            {[1, 2, 3, 4].map((v, k) => {
                              return (
                                <td key={k}>
                                  <div className="animate-pulse space-y-3 w-11/12 my-3">
                                    <div className="h-2 bg-slate-200 rounded" />
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </BorderBox>
                  )}
                  {!myReservationsDetailLoading &&
                    ReservationDetails?.rooms &&
                    ReservationDetails.rooms.map((room, k) => {
                      return (
                        <BorderBox
                          key={k}
                          className="text-lg p-8"
                        >
                          <h3 className="text-gray-800 font-semibold">{room.name}</h3>
                          <div className="flex items-center my-2 gap-2">
                            <Icon
                              name="meal"
                              size={"20"}
                              color={"#667085"}
                            />
                            <span className="text-gray-500">{room.mealTypeName}</span>
                          </div>

                          {room.roomCustomers.map((customer, index) => {
                            return (
                              <div
                                key={index}
                                className="py-6 border-b border-gray-200 last:border-b-0"
                              >
                                <table className="w-full text-left">
                                  <colgroup>
                                    <col width="33.33333%" />
                                    <col width="33.33333%" />
                                    <col width="33.33333%" />
                                  </colgroup>
                                  <thead className="text-sm font-weight-medium">
                                    <tr>
                                      <th className="pb-0.5">İsim soyisim</th>
                                      <th className="pb-0.5">Kimlik Numarası</th>
                                      <th className="pb-0.5">Doğum tarihi</th>
                                    </tr>
                                  </thead>

                                  <tbody className="text-gray-500">
                                    <tr>
                                      <td>{customer?.customerName}</td>
                                      <td>{customer?.identityNumber}</td>
                                      <td>{moment(customer?.birthDate).format("DD.MM.YYYY")}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )
                          })}
                        </BorderBox>
                      )
                    })}
                </div>

                {(myReservationsDetailLoading || ReservationDetails?.rooms?.length > 0) && (
                  <div className="mt-4">
                    <BorderBox className="text-lg p-8">
                      <h3 className="text-gray-800 font-semibold mb-2">Gecelik fiyat tablosu</h3>
                      {myReservationsDetailLoading && (
                        <table className="w-full text-left mt-4">
                          <colgroup>
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                          </colgroup>
                          <tbody>
                            <tr>
                              {[1, 2, 3, 4].map((v, k) => {
                                return (
                                  <td key={k}>
                                    <div className="animate-pulse space-y-3 w-[100px]">
                                      <div className="h-2 bg-slate-200 rounded" />
                                      <div className="grid grid-cols-3 gap-2">
                                        <div className="h-2 bg-slate-200 rounded col-span-2" />
                                        <div className="h-2 bg-slate-200 rounded col-span-1" />
                                      </div>
                                    </div>
                                  </td>
                                )
                              })}
                            </tr>
                          </tbody>
                        </table>
                      )}

                      {!myReservationsDetailLoading &&
                        ReservationDetails?.rooms &&
                        ReservationDetails.rooms.map((room, k) => {
                          let nightAmounts = room?.nightAmounts
                          if (!nightAmounts) nightAmounts = []
                          return (
                            <div
                              key={k}
                              className="swiper-area relative flex items-center mt-4"
                            >
                              {nightAmounts.length >= 7 && (
                                <div className="icon-area flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition swiper-prev">
                                  <Icon
                                    name="arrow-left"
                                    size={"24"}
                                    color={"#667085"}
                                  />
                                </div>
                              )}
                              <Swiper
                                // install Swiper modules
                                className={`w-full${nightAmounts.length >= 7 ? " mx-4" : ""}`}
                                modules={[Navigation, A11y]}
                                spaceBetween={6.5}
                                slidesPerView={nightAmounts.length >= 7 ? 7 : nightAmounts.length}
                                navigation={{
                                  prevEl: ".swiper-prev",
                                  nextEl: ".swiper-next",
                                  disabledClass: "gray-200 pointer-events-none opacity-40",
                                }}
                              >
                                {nightAmounts.map((night, index) => {
                                  return (
                                    <SwiperSlide key={index}>
                                      <div className="text-wrapper">
                                        <p className="text-gray-400 text-base font-light">
                                          {moment(night.nightDate).format("DD.MM.YYYY")}
                                        </p>
                                        <p className="text-gray-500 text-lg mt-0.5">
                                          {CurrencyFormat({
                                            currency: night?.totalPrice?.substring(0, 3),
                                            money: night.totalPrice.substring(3),
                                          })}
                                        </p>
                                      </div>
                                    </SwiperSlide>
                                  )
                                })}
                              </Swiper>
                              {nightAmounts.length >= 7 && (
                                <div className="icon-area flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition swiper-next">
                                  <Icon
                                    name="arrow-right"
                                    size={"24"}
                                    color={"#667085"}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        })}
                    </BorderBox>
                  </div>
                )}

                {(myReservationsDetailLoading || ReservationDetails?.addons?.length > 0) && (
                  <div className="mt-4">
                    <BorderBox className="text-lg p-8">
                      <h3 className="text-gray-800 font-semibold">Ek hizmetler</h3>

                      <table className="w-full text-left mt-8">
                        <colgroup>
                          <col width="33.333%" />
                          <col width="33.333%" />
                          <col width="33.333%" />
                        </colgroup>
                        <thead className="text-sm font-weight-medium">
                          <tr>
                            <th className="pb-0.5">Hizmet</th>
                            <th className="pb-0.5">Başlangıç/Bitiş</th>
                            <th className="pb-0.5">Satış bedeli</th>
                          </tr>
                        </thead>

                        <tbody className="text-gray-500">
                          {myReservationsDetailLoading && (
                            <tr>
                              {[1, 2, 3].map((v, k) => {
                                return (
                                  <td key={k}>
                                    <div className="animate-pulse space-y-3 w-11/12 my-3">
                                      <div className="h-2 bg-slate-200 rounded" />
                                    </div>
                                  </td>
                                )
                              })}
                            </tr>
                          )}
                          {!myReservationsDetailLoading &&
                            ReservationDetails?.addons &&
                            ReservationDetails?.addons.map((addon, k) => {
                              return (
                                <tr key={k}>
                                  <td>{addon.name}</td>
                                  <td>
                                    {moment(addon.startDate).format("LT")} - {moment(addon.endDate).format("LT")}
                                  </td>
                                  <td>
                                    {CurrencyFormat({
                                      currency: addon?.totalPrice?.substring(0, 3),
                                      money: addon.totalPrice.substring(3),
                                    })}
                                  </td>

                                  {/*<td>Metallica Konseri</td>
                        <td>22:00 - 00:00</td>
                        <td>₺ 1.200</td>*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </BorderBox>
                  </div>
                )}
                {!myReservationsDetailLoading && ReservationDetails?.bookingNote && (
                  <div className="mt-4">
                    <BorderBox className="text-lg p-8">
                      <h3 className="text-gray-800 font-semibold">Özel not</h3>
                      <p className="text-gray-500 mt-4">{ReservationDetails.bookingNote}</p>
                    </BorderBox>
                  </div>
                )}
                <div className="mt-4 pb-[78px]">
                  <BorderBox className="text-lg p-8">
                    <h3 className="text-gray-800 font-semibold">Fatura bilgileri</h3>

                    <table className="w-full text-left mt-8">
                      <colgroup>
                        <col width="33.3333%" />
                        <col width="33.3333%" />
                        <col width="33.3333%" />
                      </colgroup>
                      <thead className="text-sm font-weight-medium">
                        <tr>
                          <th className="pb-0.5">Tip</th>
                          <th className="pb-0.5">Ünvan</th>
                          <th className="pb-0.5">E-Posta</th>
                        </tr>
                      </thead>

                      <tbody className="text-gray-500">
                        {myReservationsDetailLoading && (
                          <tr>
                            {[1, 2, 3].map((v, k) => {
                              return (
                                <td key={k}>
                                  <div className="animate-pulse space-y-3 w-11/12 my-3">
                                    <div className="h-2 bg-slate-200 rounded" />
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        )}
                        {!myReservationsDetailLoading && ReservationDetails?.customerInvoice && (
                          <tr>
                            <td>{ReservationDetails.customerInvoice?.customerType}</td>
                            <td>{ReservationDetails.customerInvoice?.name}</td>
                            <td>{ReservationDetails.customerInvoice?.eMail}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <hr className="my-6 border-gray-200" />

                    <table className="w-full text-left mt-8">
                      <colgroup>
                        <col width="33.33333%" />
                        <col width="33.33333%" />
                        <col width="33.33333%" />
                      </colgroup>
                      <thead className="text-sm font-weight-medium">
                        <tr>
                          <th className="pb-0.5">Vergi dairesi</th>
                          <th className="pb-0.5">Vergi No</th>
                          <th className="pb-0.5">Ülke / Şehir</th>
                        </tr>
                      </thead>

                      <tbody className="text-gray-500">
                        {myReservationsDetailLoading && (
                          <tr>
                            {[1, 2, 3].map((v, k) => {
                              return (
                                <td key={k}>
                                  <div className="animate-pulse space-y-3 w-11/12 my-3">
                                    <div className="h-2 bg-slate-200 rounded" />
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        )}
                        {!myReservationsDetailLoading && ReservationDetails?.customerInvoice && (
                          <tr>
                            <td>
                              {ReservationDetails.customerInvoice?.taxOffice
                                ? ReservationDetails.customerInvoice?.taxOffice
                                : "-"}
                            </td>
                            <td>
                              {ReservationDetails.customerInvoice?.taxNumber
                                ? ReservationDetails.customerInvoice?.taxNumber
                                : "-"}
                            </td>
                            <td>
                              {ReservationDetails.customerInvoice?.country}/{ReservationDetails.customerInvoice?.city}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <hr className="my-6 border-gray-200" />

                    <table className="w-full text-left mt-8">
                      <colgroup>
                        <col width="100%" />
                      </colgroup>
                      <thead className="text-sm font-weight-medium">
                        <tr>
                          <th className="pb-0.5">Fatura adresi</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-500">
                        {myReservationsDetailLoading && (
                          <tr>
                            {[1].map((v, k) => {
                              return (
                                <td key={k}>
                                  <div className="animate-pulse space-y-3 w-full my-3">
                                    <div className="h-2 bg-slate-200 rounded" />
                                    <div className="grid grid-cols-3 gap-2">
                                      <div className="h-2 bg-slate-200 rounded col-span-2" />
                                    </div>
                                    <div className="grid grid-cols-3 gap-2">
                                      <div className="h-2 bg-slate-200 rounded col-span-1" />
                                    </div>
                                  </div>
                                </td>
                              )
                            })}
                          </tr>
                        )}
                        {!myReservationsDetailLoading && ReservationDetails?.customerInvoice && (
                          <tr>
                            <td>
                              {ReservationDetails.customerInvoice?.adress
                                ? ReservationDetails.customerInvoice?.adress
                                : "Belirtilmemiş"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </BorderBox>
                </div>
              </>
            )}
          </div>
        </section>
      </main>

      <MyReservationsApproveModal
        onChange={() => setConfirmReservationModal(false)}
        onEditorChange={(data) => setEditorData(data)}
        approveButton={() => approveReservationModal()}
        open={ConfirmReservationModal}
        width={600}
        title={"Onayla"}
        headerClass={"mb-2"}
        closeButton={true}
        reservationNo={id}
      />
    </>
  )
}

export default ReservationDetail
