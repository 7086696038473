import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

// Components
import MainHeader from "components/Headers/MainHeader"
import BackgroundIcons from "components/BackgroundIcons"
import StepBar from "components/StepBar"
import HomePage from "./Steps/HomePage"
import Navigation from "components/Navigation"
import PreviewAreaSettings from "components/Setup-Settings/PreviewAreaSettings"
import { Description, TitleH1 } from "components"

// Requests
import { useDispatch, useSelector } from "react-redux"
import { listConfigurationRequested } from "pages/B2cDemoPage/redux/actions/ListConfiguration"
import { getConfigurationRequested } from "pages/B2cDemoPage/redux/actions/GetConfiguration"
import { ThemeConfigValue } from "pages/B2cDemoPage/redux/actions/setThemeConfig"

// Constants
import { SetupConfig } from "constants/index"
import {DefaultVisualStyles} from "../PageManager/Enums";

export const Setup = (props) => {
  let { isLogged, AssociatedDomains } = props

  const dispatch = useDispatch()
  const location = useLocation()

  const DomainId = AssociatedDomains?.domainId
  const DomainType = AssociatedDomains?.domainType

  // selectors
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)
  const ListConfigurationLoading = useSelector((state) => state.ListConfiguration.loading)
  const GetConfiguration = useSelector((state) => state.GetConfiguration.result)
  const GetConfigurationLoading = useSelector((state) => state.GetConfiguration.loading)
  const DomainInfoState = useSelector((state) => state.associatedDomains.data)

  // states
  const [Config] = useState(SetupConfig.steps[DomainType])
  const [StepPages] = useState(SetupConfig.pages[DomainType])
  const [StepUrls] = useState(Config?.map((v) => v.slug))
  const [ActiveLayout, setActiveLayout] = useState(0)
  const [ShowPageContent, setShowPageContent] = useState(false)
  const [DefaultThemeButtonColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [DefaultThemeLinkColor] = useState(DefaultVisualStyles.button.backgroundColor)
  const [AllFonts, setAllFonts] = useState(null)
  const [AllDisplays, setAllDisplays] = useState(null)
  const [ActiveDisplays, setActiveDisplays] = useState(null)
  const [ActiveFontFamily, setActiveFontFamily] = useState(null)
  const [ActiveThemeButtonColor, setActiveThemeButtonColor] = useState(DefaultThemeButtonColor)
  const [ActiveThemeLinkColor, setActiveThemeLinkColor] = useState(DefaultThemeLinkColor)
  const [ActiveThemeLinkType, setActiveThemeLinkType] = useState(null)
  const [ThemeConfigBeauty, setThemeConfigBeauty] = useState()
  const [GetThemeConfig, setGetThemeConfig] = useState(null)
  const [ThemeConfig, setThemeConfig] = useState({
    displaySettings: {
      borderRadius: ActiveDisplays?.styles?.borderRadius,
      fontFamily: ActiveDisplays?.styles?.fontFamily,
    },
  })

  // Func
  const changeLayout = (stepId) => {
    setActiveLayout(stepId)
  }

  const setConfig = (config) => {
    if (config) {
      setThemeConfig({
        ...ThemeConfig,
        displaySettings: {
          ...ThemeConfig.displaySettings,
          ...config,
        },
      })
    }
  }

  const getPageRequestsSync = () => {
    dispatch(
      listConfigurationRequested({
        query: `domainType=${DomainType}`,
      }),
    )
    dispatch(
      getConfigurationRequested({
        domainType: DomainId,
      }),
    )
  }

  useEffect(() => {
    StepUrls?.map((url, index) => {
      if (location && location.pathname.indexOf(url) !== -1) setActiveLayout(index + 1)
      return true
    })

    setShowPageContent(true)
  }, [location, StepUrls])

  useEffect(() => {
    let fontFamily
    fontFamily = ActiveFontFamily
    setActiveFontFamily(fontFamily)

    setThemeConfig({
      ...ThemeConfig,
      displaySettings: {
        ...ThemeConfig.displaySettings,
        borderRadius: ActiveDisplays?.styles?.borderRadius,
        fontFamily,
        buttonColor: ActiveThemeButtonColor,
        linkColor: ActiveThemeLinkColor,
        linkType: ActiveThemeLinkType,
      },
    })
    // eslint-disable-next-line
  }, [ActiveDisplays, ActiveThemeButtonColor, ActiveThemeLinkColor, ActiveThemeLinkType, GetThemeConfig])

  useEffect(() => {
    let result = JSON.stringify(ThemeConfig, null, 4)
    setThemeConfigBeauty(result)
    dispatch(ThemeConfigValue(result))
  }, [dispatch, ThemeConfig])

  // request
  useEffect(() => {
    if (!ListConfigurationLoading && !GetConfigurationLoading && !ListConfiguration) {
      if (DomainId && DomainType) {
        getPageRequestsSync()
      } else {
        if (DomainInfoState && DomainInfoState.length) {
          getPageRequestsSync()
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, DomainInfoState, DomainId, DomainType])

  // data manipulation
  useEffect(() => {
    if (ListConfiguration !== null) {
      // add background-color to response list
      let displays = ListConfiguration?.displays.reduce((acc, value) => {
        acc.push({
          ...value,
          backgroundColor: "#EFF8FF",
        })
        return acc
      }, [])

      // compare if GetThemeConfig is not empty
      let activeDisplay = displays ? displays[0] : {}
      let activeFontFamily = ListConfiguration?.fonts[0]?.value
      let activeThemeLinkType = ListConfiguration?.links[0]?.value
      let activeThemeButtonColor = DefaultThemeButtonColor
      let activeThemeLinkColor = DefaultThemeLinkColor
      if (GetThemeConfig?.configurations.length) {
        activeDisplay = [
          GetThemeConfig?.configurations.find(({ configurationMetadataCode }) => configurationMetadataCode === "theme"),
        ].reduce((acc, value) => {
          acc = {
            label: value?.name,
            styles: value?.properties,
          }
          return acc
        }, [])

        activeFontFamily = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "font",
        )?.name
        activeThemeLinkType = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linktype",
        )?.properties?.textDecorationLine
        activeThemeButtonColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "color",
        )?.properties?.color
        activeThemeLinkColor = GetThemeConfig?.configurations.find(
          ({ configurationMetadataCode }) => configurationMetadataCode === "linkcolor",
        )?.properties?.linkcolor

        /*if(!!activeDisplay && !!activeFontFamily && !!activeThemeLinkType && !!activeThemeButtonColor){}*/
        setThemeConfig({
          ...ThemeConfig,
          displaySettings: {
            ...ThemeConfig.displaySettings,
            buttonColor: activeThemeButtonColor,
            logoValues: {
              src: GetThemeConfig?.logoUrl,
              name: GetThemeConfig?.logoUrl,
            },
          },
        })
        /*
        setActiveFontFamily(ListConfiguration.fonts[0].value)
        setActiveThemeLinkType(ListConfiguration.links[0].value)*/
      }
      setAllDisplays(displays)
      setActiveDisplays(activeDisplay)
      setAllFonts(ListConfiguration?.fonts)
      setActiveFontFamily(activeFontFamily)
      setActiveThemeLinkType(activeThemeLinkType)
      setActiveThemeButtonColor(activeThemeButtonColor)
      setActiveThemeLinkColor(activeThemeLinkColor)
    }
    // eslint-disable-next-line
  }, [GetThemeConfig, ListConfiguration])

  // getConfiguration
  useEffect(() => {
    if (GetConfiguration) {
      setGetThemeConfig(GetConfiguration)
    }
  }, [GetConfiguration])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main className="bg-gray-25 z-10">
        <section className="wrapper relative flex flex-col bg-white lg:bg-transparent">
          {/*background blur icons*/}
          <BackgroundIcons />

          {/*Page content*/}
          {ShowPageContent && (
            <>
              {ActiveLayout !== 0 && (
                <>
                  <StepBar
                    page={ActiveLayout}
                    length={StepPages?.length}
                    labels={Config?.map((v) => v.title)}
                  />
                  <article className="max-h-full overflow-auto py-10">
                    <div className="setup-container max-w-[1600px] px-10 w-full mx-auto">
                      {ActiveLayout < StepPages?.length && (
                        <>
                          <TitleH1>{Config[ActiveLayout - 1].title}</TitleH1>
                          <Description>{Config[ActiveLayout - 1].description}</Description>
                        </>
                      )}

                      <div
                        className={`flex items-center ${
                          DomainType === "B2C" ? "flex-col" : "justify-center"
                        } lg:flex-row xl:gap-28 lg:gap-20 gap-10 w-full border border-gray-200 bg-white rounded-lg px-6 py-12 mt-6`}
                      >
                        {StepPages?.map((page, index) => {
                          let StepPages = page
                          if (ActiveLayout === index + 1)
                            return (
                              <StepPages
                                key={index}
                                config={{
                                  ThemeConfig,
                                  ThemeConfigBeauty,
                                  AllDisplays,
                                  AllFonts,
                                  ActiveDisplays,
                                  DefaultThemeButtonColor,
                                }}
                                setConfig={(config) => setConfig(config)}
                                AssociatedDomains={AssociatedDomains}
                              />
                            )
                          return false
                        })}

                        {ActiveLayout < StepPages?.length - (DomainType === 0) && (
                          <PreviewAreaSettings
                            className={"w-7/12"}
                            type={DomainType}
                            config={{ ThemeConfig, ThemeConfigBeauty }}
                          />
                        )}
                      </div>
                    </div>
                  </article>

                  {ActiveLayout <= StepPages?.length && (
                    <div className="setup-container-navigation max-w-[1600px] px-10 w-full mx-auto">
                      <Navigation
                        StepsGoTo={(stepId) => changeLayout(stepId)}
                        StepId={ActiveLayout}
                        showPrev={(ActiveLayout === 0 || ActiveLayout === StepPages?.length) ? undefined : StepUrls[ActiveLayout - 2]}
                        showNext={ActiveLayout === StepPages?.length ? undefined : StepUrls[ActiveLayout]}
                        basePath={"/setup"}
                      />
                    </div>
                  )}
                </>
              )}

              {ActiveLayout === 0 && <HomePage StepsGoTo={(stepId) => changeLayout(stepId)} />}
            </>
          )}
        </section>
      </main>
    </>
  )
}

export default Setup
