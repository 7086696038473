/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

// Components
import MainHeader from "components/Headers/MainHeader"
import BackgroundIcons from "components/BackgroundIcons"
import { Alert, TitleH1, Description, Icon, LoadBar, StatusNotificationBox } from "components"
import OutsideAlerter from "pages/Reservations/OutsideAlerter"

// dxTable
import { LoadPanel } from "devextreme-react/load-panel"
import Button from "devextreme-react/button"
import DataGrid, {
  Column,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
  Toolbar,
  Item,
} from "devextreme-react/data-grid"

// img
import accountManagementWelcomeImg from "assets/img/account-management-welcome.png"

// Requests
import {
  getAllTenantsRequested,
  setTenantRequested,
  updateTenantRequested,
  deleteTenantRequested,
  getAllTenantsSucceed,
} from "../../redux/actions/accountManagement"
import {tableApiPaging} from "../../helpers/tableApiPaging";

export const AccountManagement = (props) => {
  let { isLogged, TOKEN } = props

  const dispatch = useDispatch()

  // selectors
  const allTenantsLoading = useSelector((state) => state.accountManagement.allTenants.loading)
  const allTenantsList = useSelector((state) => state.accountManagement.allTenants.list?.table)
  const allTenantsTotal = useSelector((state) => state.accountManagement.allTenants.list?.RecordsFiltered)
  const allTenantsError = useSelector((state) => state.accountManagement.allTenants.error)

  const setTenantList = useSelector((state) => state.accountManagement.setTenant.list)
  const setTenantLoading = useSelector((state) => state.accountManagement.setTenant.loading)
  const setTenantListError = useSelector((state) => state.accountManagement.setTenant.error)

  const deleteTenantList = useSelector((state) => state.accountManagement.deleteTenant.list)
  const deleteTenantLoading = useSelector((state) => state.accountManagement.deleteTenant.loading)
  const deleteTenantError = useSelector((state) => state.accountManagement.deleteTenant.error)

  const updateTenantList = useSelector((state) => state.accountManagement.updateTenant.list)
  const updateTenantLoading = useSelector((state) => state.accountManagement.updateTenant.loading)
  const updateTenantError = useSelector((state) => state.accountManagement.updateTenant.error)

  // states
  const [IsOpenTableDropdown, setIsOpenTableDropdown] = useState(null)
  const [ShowAddTenant, setShowAddTenant] = useState(false)
  const [TenantAddButtonDisabled, setTenantAddButtonDisabled] = useState(true)
  const [TenantName, setTenantName] = useState("")
  const [ShowStatusNotificationBox, setShowStatusNotificationBox] = useState(false)
  const [ShowMakePassiveAlert, setShowMakePassiveAlert] = useState(false)
  const [ShowChangeTenantNameAlert, setShowChangeTenantNameAlert] = useState(false)
  const [DisableUpdateTenantNameButton, setDisableUpdateTenantNameButton] = useState(true)
  const [WillDeleteTenant, setWillDeleteTenant] = useState(null)
  const [ChangeTenantName, setChangeTenantName] = useState(null)
  const [ErrorDeleteTenant, setErrorDeleteTenant] = useState(null)
  const [ErrorSetTenant, setErrorSetTenant] = useState(null)
  const [ErrorUpdateTenant, setErrorUpdateTenant] = useState(null)
  const [TenantPayloadData, setTenantPayloadData] = useState({
    term: "",
    page: 1,
    count: 5
  })
  const [TableStates, setTableStates] = useState({
    searchFilter: null,
    cellAlignments: "center",
    dataGrid: {
      id: "gridContainer",
      allowColumnReordering: true,
      allowColumnResizing: true,
      columnAutoWidth: true,
      showBorders: true,
      showScrollbar: "always",
      keyExpr: "Id",
      //columnHidingEnabled: true,
      dataSource: [],
      noDataText: "Aranılan kriterde kayıt bulunamadı.",
    },
    paging: {
      enabled: true,
      defaultPageSize: TenantPayloadData?.count,
    },
    pager: {
      visible: true,
      displayMode: "full",
      showPageSizeSelector: true,
      showInfo: true,
      allowedPageSizes: [5, 10, 20],
      showNavigationButtons: true,
    },
  })

  // Func

  const getAllTenantsRequest = (payload = {}) => {
    const data = {
      ...TenantPayloadData,
      ...payload,
    }
    setTenantPayloadData({...data})
    dispatch(getAllTenantsRequested(data))
  }
  const setForm = (e) => {
    let val = e.target.value
    val.length >= 4 ? setTenantAddButtonDisabled(false) : setTenantAddButtonDisabled(true)
    setTenantName(val)
  }
  const showAddTenant = () => {
    setShowAddTenant(!ShowAddTenant)
    setShowStatusNotificationBox(false)
    setTenantAddButtonDisabled(true)
  }

  const addTenantRequest = () => {
    setTenantAddButtonDisabled(true)
    dispatch(
      setTenantRequested({
        Name: TenantName,
      }),
    )
  }
  const updateTenantRequest = () => {
    let { Id, TenantId } = ChangeTenantName
    dispatch(
      updateTenantRequested({
        Id,
        TenantId,
        Name: TenantName,
      }),
    )
  }
  const deleteTenantRequest = () => {
    let { Id } = WillDeleteTenant
    dispatch(deleteTenantRequested(Id))
  }

  const showStatusNotificationBox = () => {
    setShowStatusNotificationBox(!ShowStatusNotificationBox)
    setTenantAddButtonDisabled(true)
    setErrorSetTenant(false)
  }

  const showMakePassiveAlert = () => {
    setShowMakePassiveAlert(false)
    setWillDeleteTenant(null)
    setErrorDeleteTenant(null)
  }
  const showChangeTenantNameAlert = () => {
    setShowChangeTenantNameAlert(false)
    setChangeTenantName(null)
    setErrorUpdateTenant(null)
    setTenantName("")
  }

  // effects
  useEffect(() => {
    if (TOKEN) {
      dispatch(getAllTenantsRequested(TenantPayloadData))
    }
    // eslint-disable-next-line
  }, [TOKEN])

  useEffect(() => {
    if (allTenantsError) {
      alert("Müşteri bilgilerine ulaşılamadı.")
      dispatch(getAllTenantsSucceed(null))
    }
    // eslint-disable-next-line
  }, [allTenantsError])


  useEffect(() => {
    if (allTenantsList && allTenantsList.length) {
      setTableStates({
        ...TableStates,
        pager: {
          ...TableStates.pager,
          infoText: `Sayfa ${TenantPayloadData.page} / ${Math.ceil(allTenantsTotal / TableStates.paging.defaultPageSize)} (${allTenantsTotal} veri)`,
        },
        dataGrid: {
          ...TableStates.dataGrid,
          dataSource: allTenantsList,
          onContentReady: (e) => {
            tableApiPaging(e, TableStates, setTableStates, allTenantsTotal, TenantPayloadData).then(res => getAllTenantsRequest(res.payload))
            let columnChooserView = e.component.getView("columnChooserView")
            if (!columnChooserView["_popupContainer"]) {
              columnChooserView._initializePopupContainer()
              columnChooserView.render()
              columnChooserView["_popupContainer"].option("position", {
                of: e.element,
                my: "center",
                at: "center",
                offset: "0 0",
              })
            }
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTenantsList])

  useEffect(() => {
    if (!setTenantLoading) {
      showStatusNotificationBox()
    } else {
      setTenantAddButtonDisabled(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTenantLoading])

  useEffect(() => {
    if (WillDeleteTenant) setShowMakePassiveAlert(true)
  }, [WillDeleteTenant])

  useEffect(() => {
    if (deleteTenantList) {
      setShowMakePassiveAlert(false)
      dispatch(getAllTenantsRequested())
    }
  }, [dispatch, deleteTenantList])

  useEffect(() => {
    if (updateTenantList) {
      setShowChangeTenantNameAlert(false)
      dispatch(getAllTenantsRequested())
    }
  }, [dispatch, updateTenantList])

  useEffect(() => {
    if (ChangeTenantName) setShowChangeTenantNameAlert(true)
  }, [ChangeTenantName])

  useEffect(() => {
    if (deleteTenantError) {
      setShowMakePassiveAlert(false)
      setErrorDeleteTenant({
        message: "Müşteri ekleme işlemi gerçekleşirken bir hata ile karşılaşıldı",
      })
    }
  }, [deleteTenantError])

  useEffect(() => {
    if (setTenantListError) {
      setErrorSetTenant({
        message: "Müşteri ekleme işlemi gerçekleşirken bir hata ile karşılaşıldı",
      })
    }
  }, [setTenantListError])

  useEffect(() => {
    if (updateTenantError) {
      setShowChangeTenantNameAlert(false)
      setErrorUpdateTenant({
        message: updateTenantList,
      })
    }
    // eslint-disable-next-line
  }, [updateTenantError])

  useEffect(() => {
    if (setTenantList) {
      dispatch(getAllTenantsRequested())
    }
    // eslint-disable-next-line
  }, [setTenantList])

  useEffect(() => {
    if (TenantName) {
      setTenantName("")
    }
    // eslint-disable-next-line
  }, [updateTenantList])

  // update tenant name
  useEffect(() => {
    setDisableUpdateTenantNameButton(!!!TenantName.length)
    // eslint-disable-next-line
  }, [TenantName])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main className="relative bg-gray-25 overflow-hidden z-10">
        <section className="wrapper flex flex-col bg-white lg:bg-transparent overflow-auto">
          {/*background blur icons*/}
          <BackgroundIcons />

          {/*welcome section*/}
          {allTenantsLoading && !ShowAddTenant && (
            <div className="account-management-area flex items-center justify-center h-full overflow-auto lg:overflow-hidden">
              <div className="flex items-center justify-center border border-gray-200 shadow-xl rounded-xl bg-white w-full h-full p-10">
                <LoadBar pathFill={"#2E90FA"} />
              </div>
            </div>
          )}

          {/*showAddTenant*/}
          {ShowAddTenant && (
            <>
              {!ShowStatusNotificationBox && !setTenantListError && (
                <div className="account-management-area flex items-center justify-center h-full overflow-auto lg:overflow-hidden">
                  <div className="flex flex-col border border-gray-200 shadow-xl rounded-xl bg-white w-[392px] p-6">
                    <form className="form-area w-full">
                      <div className="form-header">
                        <p className="text-lg text-gray-900 font-semibold">Müşteri Ekle</p>
                        <p className="text-sm text-gray-500 mt-2">
                          Müşteri hesaplarını ve domainlerini yönetebilmek için önce müşteri eklemelisin.
                        </p>
                      </div>
                      <div className="form-body mt-8">
                        <div className="form-wrapper flex flex-col">
                          <label
                            className="text-gray-800 text-sm font-medium mb-1"
                            htmlFor="customer_name"
                          >
                            Müşteri Adı
                          </label>
                          <input
                            id="customer_name"
                            placeholder="Müşteri adı gir"
                            type="text"
                            onChange={setForm}
                          />
                        </div>
                      </div>

                      <div className="form-footer mt-8">
                        <div className="btn-group flex gap-6">
                          <button
                            type="button"
                            className="btn outline w-full"
                            onClick={showAddTenant}
                          >
                            Vazgeç
                          </button>
                          <button
                            type="button"
                            className={`btn w-full`}
                            onClick={addTenantRequest}
                            disabled={TenantAddButtonDisabled}
                          >
                            {TenantAddButtonDisabled && setTenantLoading ? <LoadBar pathFill={"#2E90FA"} /> : "Müşteri Ekle"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <StatusNotificationBox
                show={ShowStatusNotificationBox && ErrorSetTenant?.message?.length > 0 && setTenantListError}
                title="Hata"
                error={true}
                desc={ErrorSetTenant?.message}
              >
                <div className="form-footer mt-8">
                  <div className="btn-group flex gap-6">
                    <button
                      type="button"
                      className="btn outline w-full px-2"
                      //className="btn danger w-full px-2 "
                      onClick={showStatusNotificationBox}
                    >
                      Geri
                    </button>
                  </div>
                </div>
              </StatusNotificationBox>

              <StatusNotificationBox
                show={ShowStatusNotificationBox && setTenantList}
                title="Müşteri başarıyla oluşturuldu"
                desc="Müşteri yönetimi sayfasından tanımladığınız müşterileri görüntüleyebilir ve düzenleme yapabilirsiniz."
              >
                <div className="form-footer mt-8">
                  <div className="btn-group flex gap-6">
                    <button
                      type="button"
                      className="btn outline w-full px-2"
                      onClick={showAddTenant}
                    >
                      Müşteri Yönetimi
                    </button>
                    <button
                      type="button"
                      className="btn w-full px-2"
                      onClick={showStatusNotificationBox}
                    >
                      Yeni Müşteri Ekle
                    </button>
                  </div>
                </div>
              </StatusNotificationBox>
            </>
          )}

          {!allTenantsLoading && !ShowAddTenant && (
            <>
              {/*empty states && setup*/}
              {!allTenantsTotal && !TenantPayloadData.term && (
                <div className="account-management-area flex flex-col lg:flex-row h-full overflow-auto lg:overflow-hidden">
                  <div className="lg:w-1/2 flex items-center justify-center border-t lg:border-r lg:border-t-0 border-b lg:border-b-0 border-gray-200 py-20 lg:bg-white lg:overflow-auto">
                    <div className="lg:w-[520px] max-w-[90%]">
                      <TitleH1 className="mb-4">Hadi başlayalım!</TitleH1>
                      <p className="text-gray-500 text-sm lg:text-lg">
                        Müşteri hesaplarını ve domainlerini yönetebilmek için önce müşteri eklemelisin.
                        <br />
                        <br />
                        İlk müşterini ekledikten sonra <b>Müşteri Yönetimi</b> sayfasından görüntüleme sağlayabilirsin.
                      </p>

                      <button
                        onClick={showAddTenant}
                        className="btn sm lg:md mt-4 lg:mt-8"
                      >
                        <span>Yeni Müşteri Ekle</span>
                        <Icon
                          name="arrow-right"
                          size="20"
                          color="white"
                        />
                      </button>
                    </div>
                  </div>
                  <figure className="flex lg:w-1/2 items-center justify-center xl:p-28 p-20">
                    <img
                      src={accountManagementWelcomeImg}
                      alt="Preview"
                    />
                  </figure>
                </div>
              )}

              {/*table list*/}
              {(TenantPayloadData.term.length > 0 || allTenantsTotal > 0) && (
                <div className="account-management-container max-w-[1032px] px-4 py-8 w-full mx-auto">
                  {ShowChangeTenantNameAlert && (
                    <Alert
                      title="Müşteriyi Yeniden Adlandır"
                      approveButtonText="Güncelle"
                      rejectButtonOnClick={showChangeTenantNameAlert}
                      approveButtonOnClick={updateTenantRequest}
                      disableApproveButton={DisableUpdateTenantNameButton}
                      loading={updateTenantLoading}
                    >
                      <label
                        className="inline-block text-gray-800 text-sm font-medium mb-1.5"
                        htmlFor="customerName"
                      >
                        Müşteri Adı
                      </label>
                      <input
                        id="customerName"
                        type="text"
                        className="w-full"
                        placeholder={ChangeTenantName?.Name}
                        defaultValue={ChangeTenantName?.Name}
                        onChange={(e) => setTenantName(e.target.value)}
                      />
                    </Alert>
                  )}

                  {ErrorUpdateTenant && (
                    <Alert
                      title="Hata!"
                      description={ErrorUpdateTenant?.message}
                      iconName="info"
                      iconBgColor="#FEE4E2"
                      hideApproveButton={true}
                      rejectButtonText={"Kapat"}
                      rejectButtonOnClick={() => showChangeTenantNameAlert()}
                      //loading={}
                    />
                  )}

                  {ShowMakePassiveAlert && (
                    <Alert
                      title="Pasife Al"
                      description="Pasife almak istediğine emin misin?"
                      iconName="info"
                      approveButtonText="Devam et"
                      rejectButtonOnClick={showMakePassiveAlert}
                      approveButtonOnClick={deleteTenantRequest}
                      loading={deleteTenantLoading}
                    />
                  )}
                  {ErrorDeleteTenant && (
                    <Alert
                      title="Hata!"
                      description={ErrorDeleteTenant?.message}
                      iconName="info"
                      iconBgColor="#FEE4E2"
                      hideApproveButton={true}
                      rejectButtonOnClick={() => showMakePassiveAlert()}
                      //loading={}
                    />
                  )}

                  <div className="page-info flex items-center flex-wrap">
                    <div className="page-info-text mr-4">
                      <TitleH1>Müşteri Yönetimi</TitleH1>
                      <Description className="mt-1 font-base text-gray-500">
                        Müşteri profillerini inceleyebilir veya yeni müşteriler ekleyebilirsiniz.
                      </Description>
                    </div>
                  </div>

                  <LoadPanel
                    position={{ of: `#${TableStates.dataGrid.id}` }}
                    shadingColor="rgba(0,0,0,0.4)"
                    loadPanelVisible={false}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={true}
                    visible={false}
                    text={"Yükleniyor..."}
                    //onHidden={() => false}
                  />
                  <DataGrid
                    className={"mt-6"}
                    {...TableStates.dataGrid}
                  >
                    {/*TABLE OPTIONS*/}
                    <Selection {...TableStates.selection} />

                    <Sorting mode="multiple" />
                    <SearchPanel
                      placeholder={"Müşteri arayın"}
                      visible={true}
                      width="288"
                      defaultText={TableStates.searchFilter}
                      onTextChange={(e) => {
                        const payload = {
                          ...TenantPayloadData,
                          term: e,
                          page: 1,

                        }
                        setTableStates({
                          ...TableStates,
                          searchFilter: e
                        })
                        getAllTenantsRequest(payload)
                      }}
                    />
                    <FilterRow {...TableStates.filterRow} />
                    <Paging {...TableStates.paging} />
                    <Pager {...TableStates.pager} />
                    {/*TABLE OPTIONS*/}

                    <Toolbar>
                      <Item
                        location="before"
                        name="searchPanel"
                      />
                      <Item location="after">
                        <Button
                          text="Müşteri Ekle"
                          width="auto"
                          className="!bg-blue-500 !text-white"
                          onClick={showAddTenant}
                        />
                      </Item>
                    </Toolbar>

                    {/*TABLE BODY*/}
                    <Column
                      caption="Müşteri Adı"
                      dataField="Name"
                      allowFiltering={true}
                      allowSearch={true}
                      allowHeaderFiltering={false}
                      width={TableStates.rowWidth}
                      alignment={"left"}
                    />
                    <Column
                      caption="Domain Tipi"
                      dataField="domainType"
                      cellRender={(row) => (row.text ? row.text : "-")}
                      allowFiltering={false}
                      allowHeaderFiltering={false}
                      width={TableStates.rowWidth}
                      alignment={TableStates.cellAlignments}
                      visible={false}
                    />
                    <Column
                      caption="Kullanıcı Sayısı"
                      dataField="TenantDomainUserCount"
                      allowFiltering={false}
                      allowHeaderFiltering={false}
                      width={TableStates.rowWidth}
                      alignment={TableStates.cellAlignments}
                    />
                    <Column
                      caption="Oluşturulma Tarihi"
                      dataField="CreateDate"
                      dataType="date"
                      cellRender={(row) => (row.text ? row.text : "-")}
                      allowFiltering={false}
                      allowHeaderFiltering={false}
                      width={TableStates.rowWidth}
                      alignment={TableStates.cellAlignments}
                    />
                    <Column
                      caption="Son İşlem Tarihi"
                      dataField="UpdateDate"
                      dataType="date"
                      cellRender={(row) => (row.text ? row.text : "-")}
                      allowFiltering={false}
                      allowHeaderFiltering={false}
                      width={TableStates.rowWidth}
                      alignment={TableStates.cellAlignments}
                    />
                    <Column
                      caption="İşlemler"
                      width={TableStates.rowWidth}
                      fixed={true}
                      fixedPosition={"right"}
                      cellRender={(row) => {
                        return (
                          <div className="dropdown relative">
                            <OutsideAlerter
                              as="button"
                              className="w-full"
                              index={row.key}
                              isOutsideClick={() => setIsOpenTableDropdown(null)}
                              handleClick={() =>
                                IsOpenTableDropdown !== row.key ? setIsOpenTableDropdown(row.key) : setIsOpenTableDropdown(null)
                              }
                            >
                              <Icon
                                className="pointer-events-none"
                                name="more-vertical"
                                size="20"
                                color="#1D2939"
                              />
                            </OutsideAlerter>
                            {IsOpenTableDropdown === row.key && (
                              <ul
                                className={`dropdown-body absolute right-[12px] bg-white border border-solid border-gray-100 shadow-lg mt-1 rounded-lg overflow-hidden text-sm text-gray-800 z-10`}
                              >
                                <li className="dropdown-item">
                                  <Link
                                    to={`/account-management/domains/${row.data.TenantId}`}
                                    className="block"
                                  >
                                    <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                      Domainler
                                    </button>
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <Link
                                    to={`/account-management/userlist/${row.data.TenantId}`}
                                    className="block"
                                  >
                                    <button className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left">
                                      Kullanıcı listesi
                                    </button>
                                  </Link>
                                </li>
                                <li className="dropdown-item">
                                  <button
                                    onClick={() => setChangeTenantName(row.data)}
                                    className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                  >
                                    Müşteriyi yeniden adlandır
                                  </button>
                                </li>
                                <li className="dropdown-item">
                                  <button
                                    onClick={() => setWillDeleteTenant(row.data)}
                                    className="w-full py-2.5 px-4 hover:bg-gray-50 hover:font-medium text-left"
                                  >
                                    Pasife al
                                  </button>
                                </li>
                              </ul>
                            )}
                          </div>
                        )
                      }}
                      alignment={TableStates.cellAlignments}
                    />
                    {/*TABLE BODY*/}
                  </DataGrid>
                </div>
              )}
            </>
          )}
        </section>
      </main>
    </>
  )
}

export default AccountManagement
