import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "universal-cookie"
import { Link } from "react-router-dom"

// Components
import { TitleH1 } from "components"
import Icon from "components/Icon"
import DesktopView from "components/IntegrationSettings/PreviewDesktop/DesktopView"
import MobileView from "components/IntegrationSettings/PreviewMobile/MobileView"

import { saveConfigurationRequested } from "pages/B2cDemoPage/redux/actions/SaveConfiguration"
import CorpLivePreview from "pages/B2cDemoPage/CorpLivePreview"
import {associatedDomainsRequested} from "redux/actions/associatedDomains";

const cookies = new Cookies()

const Preview = (props) => {
  let {
    config: { ThemeConfig },
  } = props

  const dispatch = useDispatch()

  const DomainId = cookies.get("associatedDomain")?.domainId
  const DomainType = cookies.get("associatedDomain")?.domainType

  // Selectors
  let getThemeConfig = useSelector((state) => state.getThemeConfig.value)
  const ListConfiguration = useSelector((state) => state.ListConfiguration.result)

  const saveChanges = async () => {
    if (!ThemeConfig.displaySettings.borderRadius) return false
    if (!DomainId) alert("domain ID bulunamadı")
    let getConfig = JSON.parse(getThemeConfig)
    let ThemeConfiguration = ListConfiguration.displays.find(
      (item) => item?.styles?.borderRadius === getConfig.displaySettings?.borderRadius,
    )
    let LinkTypeConfiguration = ListConfiguration.links.find(
      (item) => item?.styles?.textDecorationLine === getConfig.displaySettings?.linkType,
    )
    let obj = {
      ThemeConfiguration: ThemeConfiguration?.label,
      ColorConfiguration: JSON.stringify({ color: ThemeConfig?.displaySettings?.buttonColor }),
      FontConfiguration: getConfig?.displaySettings?.fontFamily,
    }

    if (LinkTypeConfiguration) {
      obj = {
        ...obj,
        LinkTypeConfiguration: LinkTypeConfiguration?.label,
        /*LinkColorConfiguration: JSON.stringify({
          linkcolor: getConfig?.displaySettings?.linkColorHex,
        }),*/
      }
    }

    var formData = new FormData()
    for (let key in obj) {
      formData.append(key, obj[key])
    }

    const lpLogoFile = ThemeConfig.displaySettings?.logoValues?.fileForBinary
    if (!lpLogoFile) {
      await fetch(ThemeConfig.displaySettings?.logoValues.src)
        .then((res) => res.blob())
        .then((file) => {
          formData.append("ClientLogo", file, file.name)
        })
    } else {
      formData.append("ClientLogo", lpLogoFile, lpLogoFile.name)
    }
    dispatch(saveConfigurationRequested(formData))
  }

  useEffect(() => {
    saveChanges()
    return () => {
      dispatch(associatedDomainsRequested())
    };
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {DomainType === "Corporate" && (
        <CorpLivePreview
          config={{
            ThemeConfig,
          }}
        />
      )}
      {DomainType !== "Corporate" && (
        <>
          <div className="text-box w-full p-4">
            <TitleH1>Rezervasyon sayfan hazır!</TitleH1>
            <p className="text-base text-gray-500 mt-4">
              Ayarların hepsini tamamladın, kurulumu bitirmeden önce yaptığın ayarları inceleyebilmen için sana bir ön izleme
              hazırladık.
              <br />
              <br />
              Kurulumu bitirdikten sonra ayarlarını güncelleyebilirsin.
            </p>
            <Link
              to={DomainType === "Otel" ? "/view-settings" : "/theme-settings/configuration"}
              className="btn px-10 mt-4"
            >
              <Icon
                name="eye"
                size="20"
                color="white"
              />
              <span>İncele</span>
            </Link>
          </div>
          <div className="image-area w-full mr-6">
            <div className="relative">
              <div>
                <DesktopView
                  logo={ThemeConfig?.displaySettings?.logoValues?.src}
                  color={ThemeConfig?.displaySettings?.buttonColor}
                  borderRadius={ThemeConfig?.displaySettings?.borderRadius}
                  fontFamily={ThemeConfig?.displaySettings?.fontFamily}
                  domainType={DomainType}
                />
              </div>
              <div className="absolute top-[24%] right-[-5%] z-30">
                <MobileView
                  logo={ThemeConfig?.displaySettings?.logoValues?.src}
                  color={ThemeConfig?.displaySettings?.buttonColor}
                  borderRadius={ThemeConfig?.displaySettings?.borderRadius}
                  fontFamily={ThemeConfig?.displaySettings?.fontFamily}
                  domainType={DomainType}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Preview
