import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import MainHeader from "components/Headers/MainHeader"
import { TitleH1, Modal, Icon, Description } from "components"
import ImageUploading from "react-images-uploading"
import AvatarEditor from "react-avatar-editor"
import { Link } from "react-router-dom"

import { profileImageRequested } from "redux/actions/profileImage"
import {sizeCalculator} from "utils/sizeCalculator";

function MyAccountPage(props) {
  let { isLogged, UserInfo } = props
  let { name, email } = UserInfo

  const dispatch = useDispatch()

  //Refs
  const editor = useRef(null)

  // selectors
  const profileImageSelect = useSelector((state) => state?.profileImage?.data)

  // states
  const [editProfile, setEditProfile] = useState(false)
  const [logoUploadModal, setLogoUploadModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [scale, setScale] = useState(0.5)
  const [images, setImages] = useState([])
  const [ownerPasswordType, setOwnerPasswordType] = useState(false)
  const [resetMailModal, setResetMailModal] = useState(false)
  const [resetPasswordSuccessModal, setResetPasswordSuccessModal] = useState(false)
  const [PasswordList, setPasswordList] = useState({
    pw1: {
      type: "password",
      isShow: false,
      placeholder: "Şifre",
    },
    pw2: {
      type: "password",
      isShow: false,
      placeholder: "Şifre tekrar",
    },
  })

  const togglePassword = (pw) => {
    let isText = PasswordList[pw].type === "text"
    setPasswordList({
      ...PasswordList,
      [pw]: {
        ...PasswordList[pw],
        type: isText ? "password" : "text",
        isShow: !isText,
      },
    })
  }

  const setPassword = (e, pw) => {
    let pwValue = e.target.value

    checkPasswordRequiretments(pw, pwValue)
  }

  const checkPasswordRequiretments = (pw, pwValue) => {
    let minLength = pwValue.length >= 8
    let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pwValue) // eslint-disable-line
    let isContainsNumber = /\d/.test(pwValue) // eslint-disable-line
    let isContainsUpperCase = /[A-Z]/.test(pwValue) // eslint-disable-line
    let isContainsLowerCase = /[a-z]/.test(pwValue) // eslint-disable-line
    let isContainsLowerAndUpperCase = isContainsUpperCase && isContainsLowerCase

    setPasswordList({
      ...PasswordList,
      [pw]: {
        ...PasswordList[pw],
        value: pwValue,
        minLength,
        specialChars,
        isContainsNumber,
        isContainsLowerAndUpperCase,
      },
    })
  }

  const handleScale = (e) => {
    setScale(e.target.value)
  }

  const scalePlus = (scale) => {
    setScale(scale + 0.1)
  }

  const scaleDown = (scale) => {
    setScale(scale - 0.1)
  }

  const handleSave = () => {
    const img = editor.current?.getImageScaledToCanvas().toDataURL()
    const rect = editor.current?.getCroppingRect()
    if (!img || !rect) return
    const data = { data_url: img }
    dispatch(profileImageRequested(data))
  }

  const handleDelete = () => {
    const data = { data_url: null }
    dispatch(profileImageRequested(data))
  }

  useEffect(() => {
    profileImageSelect && setImages([profileImageSelect])
  }, [profileImageSelect])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1920px] overflow-auto">
          <div className="container">
            <div className="flex flex-row justify-between mb-6">
              <div className=" justify-start">
                <TitleH1>Hesabım</TitleH1>
                <Description>Profil bilgilerinizi güncel tutun.</Description>
              </div>
              <div className="justify-end">
                {!editProfile ? (
                  <button
                    onClick={() => setEditProfile(true)}
                    className="btn lg"
                  >
                    Düzenle
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => setEditProfile(false)}
                      className="btn outline lg mr-2"
                    >
                      Vazgeç
                    </button>
                    <button
                      onClick={() => setEditProfile(false)}
                      className="btn lg"
                    >
                      Düzenle
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col bg-white border border-solid border-gray-200 p-8 rounded-lg">
              <div className="flex flex-row justify-between mb-4">
                <div className=" justify-start">
                  <p className="text-sm text-gray-800 font-medium">Profil resmi</p>
                </div>
                <div className="flex flex-col items-end">
                  <div className="bg-blue-50 flex items-center justify-center w-16 h-16 rounded-full mb-5">
                    {!(profileImageSelect?.data_url === null) && !(profileImageSelect === null) ? (
                      <img
                        src={images[0]?.data_url}
                        alt="Logo"
                        className="rounded-full"
                      />
                    ) : (
                      <Icon
                        name="user"
                        color="#2E90FA"
                        size="30"
                      />
                    )}
                  </div>
                  {!(profileImageSelect?.data_url === null) && !(profileImageSelect === null) ? (
                    <>
                      {editProfile && (
                        <div className="flex flex-row justify-end">
                          <p
                            onClick={() => handleDelete()}
                            className="text-sm text-error-500 cursor-pointer mr-4"
                          >
                            Sil
                          </p>
                          <p
                            onClick={() => {
                              setEditProfileImage(true)
                              setLogoUploadModal(true)
                            }}
                            className="text-sm text-blue-500 cursor-pointer"
                          >
                            Değiştir
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {editProfile && (
                        <p
                          onClick={() => setLogoUploadModal(true)}
                          className="text-sm text-blue-500 cursor-pointer justify-end"
                        >
                          Ekle
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="h-[1px] bg-gray-200 mb-4"></div>
              <div className="flex flex-row justify-between mb-4">
                <div className="justify-start">
                  <p className="text-sm text-gray-800 font-medium">İsim Soyisim</p>
                </div>
                <div className="w-2/5 flex flex-col items-end">
                  <input
                    disabled={!editProfile}
                    className="w-full mb-1"
                    type="text"
                    value={name}
                  />
                </div>
              </div>
              <div className="h-[1px] bg-gray-200 mb-4"></div>
              <div className="flex flex-row justify-between mb-4">
                <div className="justify-start">
                  <p className="text-sm text-gray-800 font-medium">Telefon</p>
                </div>
                <div className="w-2/5 flex flex-col items-end">
                  <input
                    disabled={!editProfile}
                    className="w-full mb-1"
                    type="text"
                    value="-"
                  />
                </div>
              </div>
              <div className="h-[1px] bg-gray-200 mb-4"></div>
              <div className="flex flex-row justify-between mb-4">
                <div className=" justify-start">
                  <p className="text-sm text-gray-800 font-medium">E-Posta</p>
                </div>
                <div className="w-2/5 flex flex-col items-end">
                  <input
                    disabled={!editProfile}
                    className="w-full mb-1"
                    type="text"
                    value={email}
                  />
                  {editProfile && (
                    <p
                      onClick={() => setResetMailModal(true)}
                      className="text-sm text-blue-500 cursor-pointer justify-end"
                    >
                      Doğrula
                    </p>
                  )}
                </div>
              </div>
              <div className="h-[1px] bg-gray-200 mb-4"></div>
              <div className="flex flex-row justify-between mb-4">
                <div className="justify-start">
                  <p className="text-sm text-gray-800 font-medium">Şifre</p>
                </div>
                <div className="w-2/5 flex flex-col items-end">
                  <input
                    disabled={!editProfile}
                    className="w-full mb-1"
                    type="password"
                    value="*****"
                  />
                  {editProfile && (
                    <p
                      onClick={() => setPasswordModal(true)}
                      className="text-sm text-blue-500 cursor-pointer justify-end"
                    >
                      Şifre Değiştir
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal
        title="Profil resmi"
        closeButton={true}
        onChange={() => {
          setLogoUploadModal(false)
        }}
        open={logoUploadModal}
        width={600}
      >
        {editProfileImage ? (
          <div className="flex flex-col items-center justify-center">
            <AvatarEditor
              ref={editor}
              image={images[0]?.data_url}
              width={500}
              height={500}
              border={10}
              color={[255, 255, 255, 0.9]} // RGBA
              scale={scale}
              rotate={0}
              borderRadius={999}
            />
            <div className="flex flex-row items-center w-full mt-5">
              <Icon
                onClick={() => scaleDown(scale)}
                className="mr-8 cursor-pointer"
                name="zoom-out"
                color="#98A2B3"
                size="25"
              />
              <input
                className="rounded-lg appearance-none overflow-hidden bg-gray-400 h-4 w-full"
                name="scale"
                type="range"
                onChange={(e) => handleScale(e)}
                min={"0,5"}
                max="2"
                step="0.01"
                value={scale}
                defaultValue={scale}
              />
              <Icon
                onClick={() => scalePlus(scale)}
                className="ml-8 cursor-pointer"
                name="zoom-in"
                color="#98A2B3"
                size="25"
              />
            </div>
            <div className="flex flex-row w-full justify-end mt-6">
              <button
                onClick={() => {
                  handleDelete()
                  setLogoUploadModal(false)
                }}
                className="btn lg text !text-error-500 mr-2"
              >
                Sil
              </button>
              <button
                onClick={() => {
                  handleSave()
                  setLogoUploadModal(false)
                  setEditProfileImage(false)
                }}
                className="btn lg"
              >
                Ekle
              </button>
            </div>
          </div>
        ) : (
          <>
            <ImageUploading
              multiple={false}
              value={images}
              onChange={(imageList, addUpdateIndex) => {
                setImages(imageList[0])
                dispatch(profileImageRequested(imageList[0]))
              }}
              dataURLKey="data_url"
              acceptType={["jpg", "svg", "png", "gif"]}
            >
              {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) =>
                !(profileImageSelect?.data_url === null) && !(profileImageSelect === null) ? (
                  <>
                    <div className="w-full">
                      <div className="h-[174px] flex items-center justify-center p-4 mb-4">
                        <img
                          src={images[0]?.data_url}
                          alt="Logo"
                          className="max-h-20"
                        />
                      </div>

                      <div className="flex gap-4 items-start relative text-sm font-medium border border-blue-500 rounded-lg p-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="37"
                          viewBox="0 0 36 37"
                          fill="none"
                        >
                          <rect
                            x="2"
                            y="2.15765"
                            width="32"
                            height="32"
                            rx="16"
                            fill="#D1E9FF"
                          />
                          <g clipPath="url(#clip0_401_21187)">
                            <path
                              d="M18.6666 11.491H14C13.6463 11.491 13.3072 11.6314 13.0572 11.8815C12.8071 12.1315 12.6666 12.4707 12.6666 12.8243V23.491C12.6666 23.8446 12.8071 24.1837 13.0572 24.4338C13.3072 24.6838 13.6463 24.8243 14 24.8243H22C22.3536 24.8243 22.6927 24.6838 22.9428 24.4338C23.1928 24.1837 23.3333 23.8446 23.3333 23.491V16.1576M18.6666 11.491L23.3333 16.1576M18.6666 11.491V16.1576H23.3333"
                              stroke="#2E90FA"
                              strokeWidth="1.33333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <rect
                            x="2"
                            y="2.15765"
                            width="32"
                            height="32"
                            rx="16"
                            stroke="#EFF8FF"
                            strokeWidth="4"
                          />
                          <defs>
                            <clipPath id="clip0_401_21187">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(10 10.1577)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className="flex flex-col">
                          <strong>{images[0]?.file?.name}</strong>
                          <span className="text-gray-500 text-sm">{sizeCalculator(images[0]?.file?.size)}</span>
                        </div>

                        <div className="absolute h-5 w-5 right-4 top-4">
                          <button
                            onClick={() => {
                              dispatch(profileImageRequested({}))
                              onImageRemove(0)
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.28594 5.44361C5.61138 5.11817 6.13902 5.11817 6.46446 5.44361L9.99999 8.97914L13.5355 5.44361C13.861 5.11817 14.3886 5.11817 14.714 5.44361C15.0395 5.76905 15.0395 6.29668 14.714 6.62212L11.1785 10.1577L14.714 13.6932C15.0395 14.0186 15.0395 14.5463 14.714 14.8717C14.3886 15.1971 13.861 15.1971 13.5355 14.8717L9.99999 11.3362L6.46446 14.8717C6.13902 15.1971 5.61138 15.1971 5.28594 14.8717C4.96051 14.5463 4.96051 14.0186 5.28594 13.6932L8.82148 10.1577L5.28594 6.62212C4.96051 6.29668 4.96051 5.76905 5.28594 5.44361Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center w-full bg-gray-50 rounded-lg border border-gray-200 border-dashed cursor-pointer hover:bg-gray-100">
                    <div className="upload__image-wrapper">
                      <button
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <div className="flex flex-col justify-center items-center py-4 px-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 46 46"
                            fill="none"
                          >
                            <rect
                              x="3"
                              y="3"
                              width="40"
                              height="40"
                              rx="20"
                              fill="#EFF8FF"
                            />
                            <g clipPath="url(#clip0_401_35206)">
                              <path
                                d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832"
                                stroke="#2E90FA"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <rect
                              x="3"
                              y="3"
                              width="40"
                              height="40"
                              rx="20"
                              stroke="#F5FAFF"
                              strokeWidth="6"
                            />
                            <defs>
                              <clipPath id="clip0_401_35206">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(13 13)"
                                />
                              </clipPath>
                            </defs>
                          </svg>

                          <p className={`mb-1 mt-3 text-sm  text-center ${isDragging ? "text-blue-500" : "text-gray-500"}`}>
                            <span className="font-normal">
                              Yüklemek için <button className="font-medium text-blue-500">tıklayın</button> ya da sürükleyip
                              bırakın.
                            </span>
                          </p>
                          <p className="text-xs text-gray-500 text-center">(maks. 2 MB) SVG, PNG, ya da JPEG</p>
                        </div>
                      </button>
                    </div>
                  </div>
                )
              }
            </ImageUploading>
            <button
              onClick={() => setEditProfileImage(true)}
              className={
                !(profileImageSelect === null) && !(profileImageSelect?.data_url === null)
                  ? "btn lg w-full mt-8"
                  : "btn lg secondary disabled w-full mt-8"
              }
            >
              Ekle
            </button>
          </>
        )}
      </Modal>

      <Modal
        title="Şifre Değiştir"
        closeButton={true}
        onChange={() => {
          setPasswordModal(false)
        }}
        open={passwordModal}
        width={400}
      >
        <p className="text-base text-gray-500 mb-8">Şifre değişikliği yapabilmek için kimliğini doğrulamalısın.</p>
        <form className="mb-8">
          <div className="form-wrapper mb-4">
            <input
              className="w-full placeholder:text-slate-500"
              type={ownerPasswordType ? "text" : "password"}
              placeholder={"Mevcut Şifre"}
              required
              autoComplete="none"
            />
            <div
              className="absolute flex items-center px-3.5 inset-y-0 right-0 cursor-pointer"
              onClick={() => setOwnerPasswordType(!ownerPasswordType)}
            >
              <Icon
                name={`eye${!ownerPasswordType ? "-off" : ""}`}
                size="16"
                color={!ownerPasswordType ? "#98A2B3" : "#2E90FA"}
              />
            </div>
          </div>
          {Object.values(PasswordList).map((pw, k) => {
            return (
              <div key={k}>
                <div className="form-wrapper">
                  <input
                    onChange={(e) => setPassword(e, Object.keys(PasswordList)[k])}
                    className="w-full placeholder:text-slate-500"
                    type={pw.type}
                    placeholder={pw.placeholder}
                    required
                    autoComplete="none"
                  />
                  <div
                    className="absolute flex items-center px-3.5 inset-y-0 right-0 cursor-pointer"
                    onClick={() => togglePassword(Object.keys(PasswordList)[k])}
                  >
                    <Icon
                      name={`eye${!pw.isShow ? "-off" : ""}`}
                      size="16"
                      color={!pw.isShow ? "#98A2B3" : "#2E90FA"}
                    />
                  </div>
                </div>

                {k === 0 && (
                  <div className="text-gray-400 text-xs mt-1.5">
                    <p className={`mb-1 ${pw.minLength ? "text-success-400" : ""}`}>*Minimum 8 karakter</p>
                    <p className={`mb-1 ${pw.isContainsNumber ? "text-success-400" : ""}`}>*Minimum bir adet rakam</p>
                    <p className={`mb-1 ${pw.specialChars ? "text-success-400" : ""}`}>*Minimum bir adet sembol</p>
                    <p className={`mb-1 ${pw.isContainsLowerAndUpperCase ? "text-success-400" : ""}`}>*Büyük ve küçük harf</p>
                  </div>
                )}
              </div>
            )
          })}
        </form>
        <button
          onClick={() => {
            setPasswordModal(false)
            setResetPasswordSuccessModal(true)
          }}
          className="btn lg w-full mb-4"
        >
          Değiştir
        </button>
        <Link
          className="flex justify-center text-primary-500 text-sm text-center font-medium"
          to="/forgot-password"
        >
          Şifremi Unuttum
        </Link>
      </Modal>

      <Modal
        onChange={() => {
          setResetPasswordSuccessModal(false)
        }}
        open={resetPasswordSuccessModal}
        width={400}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-12 h-12 bg-success-50 rounded-lg mb-5">
            <Icon
              className=""
              name="check"
              color="#12B76A"
              size="20"
            />
          </div>
          <p className="text-lg font-medium text-gray-800 m-2">İşlem başarılı!</p>
          <p className="text-base text-gray-500 mb-8 text-center">Şifre başarıyla değiştirildi.</p>
          <button
            onClick={() => setResetPasswordSuccessModal(false)}
            className="btn lg w-full"
          >
            Kapat
          </button>
        </div>
      </Modal>

      <Modal
        onChange={() => {
          setResetMailModal(false)
        }}
        open={resetMailModal}
        width={400}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-12 h-12 bg-success-50 rounded-lg mb-5">
            <Icon
              className=""
              name="check"
              color="#12B76A"
              size="20"
            ></Icon>
          </div>
          <p className="text-lg font-medium text-gray-800 m-2">Sıfırlama maili gönderildi</p>
          <p className="text-base text-gray-500 mb-8 text-center">
            can@hotellacasa.com adresine gönderilen bağlantıya tıklayarak, sıfırlama işlemine başlayabilirsin.
          </p>
          <button
            onClick={() => setResetMailModal(false)}
            className="btn lg w-full"
          >
            Kapat
          </button>
        </div>
      </Modal>
    </>
  )
}

export default MyAccountPage
