import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/associatedDomains"

import { ASSOCIATED_DOMAINS_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchGetAssociatedDomains() {
  yield takeLatest(ASSOCIATED_DOMAINS_REQUESTED, getAssociatedDomains)
}

function* getAssociatedDomains() {
  try {
    const res = yield call(apiGetAssociatedDomains)
    if (res.data && res.data.length) {
      yield put(actions.associatedDomainsSucceed(res?.data))
    } else {
      // eslint-disable-next-line
      throw "Domain bilgilerine ulaşılamadı."
    }
  } catch (err) {
    yield put(actions.associatedDomainsFailed())
  }
}

const apiGetAssociatedDomains = () => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/associated`)
}

export default function* associatedDomains() {
  yield fork(watchGetAssociatedDomains)
}
