export const convertSvgUrlToBase64 = async (file) => {
	// convert application/octet-stream file
	return await fetch(file)
		.then(response => response.text())
		.then(text => {
			const parser = new DOMParser(),
				parsed = parser.parseFromString(text, 'text/html'),
				svg = parsed.querySelector('svg'),
				serializeToString = new XMLSerializer().serializeToString(svg),
				encodedData = window.btoa(serializeToString);
			// update and return converted url
			return "data:image/svg+xml;base64," + encodedData
		});

}