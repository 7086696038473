import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import GoogleMap from "google-map-react"

// components
import MainHeader from "components/Headers/MainHeader"
import {Radio, RadioGroup, SelectPicker} from "rsuite"
import {BorderBox, ErrorMessage, Icon, TextareaInput} from "components";

// localization
import localization from "../../localization"

import MyHotelSkeleton from "skeletons/MyHotel"

//utils
import {getPropertyById, getPropertyValueByName} from "utils/propertyUtils";

// saga requests
import { MyWorkAreaRequested } from "redux/actions/myWorkAreaInfo"
import { clientConfigGetRequested } from "redux/actions/clientConfigurationGet"
import { citiesRequested, countriesRequested, districtsRequested } from "redux/actions/destinations"
import { footerUptadeRequested } from "redux/actions/footerUptade"
import {getSettingsRequested} from "redux/actions/getSettings";
import {saveUserSettingsRequested} from "redux/actions/saveUserSettings";

const Marker = ({ children }) => (
  <div
    style={{
      backgroundColor: "white",
      width: "30px",
      height: "30px",
      padding: "5px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {children}
  </div>
)

const MyHotel = (props) => {
  let { isLogged, AssociatedDomains } = props

  const dispatch = useDispatch()

  const DomainType = AssociatedDomains?.domainType

  //refs
  const inputPhoneRef = useRef()
  const inputEmailRef = useRef()
  const countryWrapper = useRef()
  const cityWrapper = useRef()
  const districtWrapper = useRef()
  const domainProductWrapper = useRef()

  // selectors
  const myWorkAreaInfoList = useSelector((state) => state.myWorkAreaInfo?.list)
  const myWorkAreaInfoLoading = useSelector((state) => state.myWorkAreaInfo?.loading)
  const myWorkAreaInfoError = useSelector((state) => state.myWorkAreaInfo?.error)
  const clientConfigurationGet = useSelector((state) => state.clientConfigurationGet?.data)
  const clientConfigLoading = useSelector((state) => state.clientConfigurationGet?.loading)
  const clientConfigError = useSelector((state) => state.clientConfigurationGet?.error)
  const countries = useSelector((state) => state.destinations?.countries)
  const countriesLoading = useSelector((state) => state.destinations?.countries?.loading)
  const cities = useSelector((state) => state.destinations?.cities)
  const citiesLoading = useSelector((state) => state.destinations?.cities?.loading)
  const districts = useSelector((state) => state.destinations?.districts)
  const districtsLoading = useSelector((state) => state.destinations?.districts?.loading)
  const getSettingsData = useSelector((state) => state.getSettings.list?.Result)
  const getSettingsLoading = useSelector((state) => state.getSettings.loading)
  const getSettingsError = useSelector((state) => state.getSettings.error)

  // states
  const [IsEditableFields, setIsEditableFields] = useState(false)
  const [MyWorkAreaLoaction, setMyWorkAreaLoaction] = useState({})
  const [Countries, setCountries] = useState([])
  const [Cities, setCities] = useState([])
  const [Districts, setDistricts] = useState([])
  const [ActiveCountry, setActiveCountry] = useState()
  const [ActiveCity, setActiveCity] = useState()
  const [ActiveDistrict, setActiveDistrict] = useState()
  const [AdressValue, setAdressValue] = useState()
  const [FormInfo, setFormInfo] = useState({})
  const [Errors, setErrors] = useState({})
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const [Skeleton, setSkeleton] = useState(false)
  const [DomainProducts, setDomainProducts] = useState([])
  const [ActiveDomainProduct, setActiveDomainProduct] = useState()
  const [CallCenterPropertyList, setCallCenterPropertyList] = useState({})
  const [CallCenterEditable, setCallCenterEditable] = useState(false)

  const setForm = (e) => {
    switch (e.target.name) {
      case "email":
        if (!/\S+@\S+\.\S+/.test(e.target.value) && inputEmailRef?.current?.value.length > 0) {
          setErrors({ ...Errors, email: "Hatalı mail adresi!" })
        } else {
          setErrors({ ...Errors, email: "" })
        }
        break
      case "phone":
        if (e.target.value.length <= 6) {
          setErrors({ ...Errors, phone: "Telefon numarası en az 7 haneli olmalıdır!" })
          setBtnDisabled(true)
        } else {
          setErrors({ ...Errors, phone: "" })
          setBtnDisabled(false)
        }
        break
      default:
        break
    }
    setFormInfo({
      ...FormInfo,
      [e.target.name]: e.target.value,
    })
  }

  const saveButton = () => {
    let obj = {
      HotelDetailId: myWorkAreaInfoList?.id,
      Address: FormInfo?.address ? FormInfo?.address : AdressValue,
      Email: inputEmailRef.current?.value,
      Phone: FormInfo?.phone ? FormInfo?.phone : myWorkAreaInfoList?.phone,
      Longitude: myWorkAreaInfoList?.longitude ? myWorkAreaInfoList?.longitude : null,
      Latitude: myWorkAreaInfoList?.latitude ? myWorkAreaInfoList?.latitude : null,
      ZoomLevel: myWorkAreaInfoList?.latitude?.zoomLevel ? myWorkAreaInfoList?.latitude?.zoomLevel : 1,
      DestinationCountryId: ActiveCountry ? ActiveCountry : null,
      DestinationCityId: ActiveCity ? ActiveCity : null,
      DestinationDistrictId: ActiveDistrict ? ActiveDistrict : null,
    }
    dispatch(footerUptadeRequested(obj))
    setIsEditableFields(false)
  }

  const domainProductSelect = (value, item) => {
    if (clientConfigurationGet && !clientConfigLoading) {
      setSkeleton(true)
      dispatch(MyWorkAreaRequested(value))
      setActiveDomainProduct(item?.value)
    } else {
      setSkeleton(false)
    }
  }

  const saveCallCenterProperties = () => {
    const properties = Object.values(CallCenterPropertyList).map(list => {
      return {...list}
    })
    dispatch(
      saveUserSettingsRequested({
        properties,
      }),
    )
  }

  useEffect(() => {
    dispatch(clientConfigGetRequested())
    dispatch(getSettingsRequested())
    setSkeleton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getSettingsData){
      const callCenterPhone = getPropertyValueByName(getSettingsData, "Çağrı Merkezi")
      const callCenterInformation = getPropertyValueByName(getSettingsData, "Çağrı Merkezi Bilgilendirme")

      const properties = {
        callCenterPhone: {
          PropertyId: getPropertyById(getSettingsData, "Çağrı Merkezi"),
          PropertyValue: callCenterPhone || "",
          Status: callCenterPhone ? 1 : 0,
        },
        callCenterDescription: {
          PropertyId: getPropertyById(getSettingsData, "Çağrı Merkezi Bilgilendirme"),
          PropertyValue: callCenterInformation || "",
          Status: callCenterInformation ? 1 : 0,
        },
      }
      setCallCenterPropertyList(properties)
    }
  }, [getSettingsData])

  useEffect(() => {
    if (clientConfigurationGet) {
      const arr = []
      setSkeleton(true)
      dispatch(MyWorkAreaRequested(clientConfigurationGet?.domainProducts[0]?.id))
      setActiveDomainProduct(clientConfigurationGet?.domainProducts[0]?.id)
      clientConfigurationGet?.domainProducts.map((e) => arr.push({ label: e?.name, value: e?.id }))
      setDomainProducts(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfigurationGet])

  useEffect(() => {
    if (clientConfigError) {
      setSkeleton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfigError])

  useEffect(() => {
    if (IsEditableFields) {
      dispatch(countriesRequested())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IsEditableFields])

  useEffect(() => {
    if (countries.list.length && ActiveCountry && countriesRequested && IsEditableFields) {
      dispatch(citiesRequested(ActiveCountry))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, ActiveCountry, IsEditableFields])

  useEffect(() => {
    if (cities.list.length && ActiveCity && citiesRequested && IsEditableFields) {
      dispatch(districtsRequested(ActiveCity))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, ActiveCity, IsEditableFields])

  useEffect(() => {
    // setState when countries setted from saga
    if (countries.list.length) {
      setCountries(JSON.parse(countries.list))
    }
    if (cities.list.length) {
      setCities(JSON.parse(cities.list))
    }
    if (districts.list.length) {
      setDistricts(JSON.parse(districts.list))
    }
  }, [countries, cities, districts])

  useEffect(() => {
    if (clientConfigurationGet && myWorkAreaInfoList) {
      setSkeleton(false)
    }

    if (myWorkAreaInfoList) {
      // setMyHotelLocation
      setMyWorkAreaLoaction({
        lng: myWorkAreaInfoList?.longitude,
        lat: myWorkAreaInfoList?.latitude,
      })

      setAdressValue(myWorkAreaInfoList?.address?.replace(/<[^>]+>/g, ""))
      setActiveCountry(myWorkAreaInfoList?.destinationCountryId)
      setActiveCity(myWorkAreaInfoList?.destinationCityId)
      setActiveDistrict(myWorkAreaInfoList?.destinationDistrictId)
    }
  }, [clientConfigurationGet, myWorkAreaInfoList])

  useEffect(() => {
    if (inputPhoneRef?.current?.value.length > 0 && ActiveCity === null) {
      setBtnDisabled(true)
    } else {
      setBtnDisabled(false)
    }
  }, [inputPhoneRef, ActiveCity])

  return (
    <>
      <MainHeader isLogged={isLogged} />
      <main>
        <section className="p-10 my-0 mx-auto max-w-[1440px]">
          <div className="page-container">

            {Skeleton && !myWorkAreaInfoError && <MyHotelSkeleton />}

            {((clientConfigError || myWorkAreaInfoError) && !clientConfigLoading) && (
              <ErrorMessage className="mt-1 mb-4">Otel`e ait bilgiler yuklenirken bir hata ile karsilasildi.</ErrorMessage>
            )}

            <div className="flex flex-col items-center">
              <div className="w-full transition-[width] duration-500 gap-6 flex flex-col">

                {!myWorkAreaInfoLoading && !myWorkAreaInfoError && !clientConfigLoading && !clientConfigError && (
                  <>
                    <div className="page-top-area flex justify-between items-center">
                      <div className="page-title">
                        <h3 className="text-3xl font-semibold">Otelim</h3>
                        <p className="font-normal mt-1">Otelinize ait bilgileri buradan görüntüleyebilirsiniz.</p>
                      </div>
                      {DomainType !== "B2C" && DomainProducts?.length > 1 && (
                        <div
                          ref={domainProductWrapper}
                          className="country-wrapper relative"
                        >
                          <SelectPicker
                            block
                            size="lg"
                            placement="bottomStart"
                            placeholder={""}
                            defaultValue={ActiveDomainProduct}
                            menuMaxHeight={164}
                            cleanable={false}
                            loading={!!clientConfigLoading}
                            data={DomainProducts}
                            searchable={DomainProducts?.length > 7}
                            container={domainProductWrapper.current}
                            onSelect={(value, item) => {
                              domainProductSelect(value, item)
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="bg-white border border-gray-200 rounded-lg text-lg p-8">
                      <div className="grid sm:grid-cols-2">
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Logo</p>
                          <div className="text-gray-500">
                            <img
                              src={myWorkAreaInfoList?.logoUrl}
                              className="max-h-7 max-w-[150px] object-cover"
                              alt="my-hotel-logo"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Otel Adı</p>
                          <div className="text-gray-500">
                            <p>{myWorkAreaInfoList?.name}</p>
                          </div>
                        </div>
                      </div>

                      <hr className="my-8 border-gray-200" />

                      <div className="grid sm:grid-cols-2">
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Check In / Check Out</p>
                          <div className="text-gray-500">
                            <p>
                              {myWorkAreaInfoList?.checkIn} / {myWorkAreaInfoList?.checkOut}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <p className="text-sm font-semibold pb-0.5">Kapasite</p>
                          <div className="text-gray-500">
                            <p>{myWorkAreaInfoList?.roomCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white border border-gray-200 rounded-lg text-lg p-8">
                      <div className="flex flex-col sm:flex-row sm:items-center gap-3 border-bottom border-gray-200 pb-5">
                        <div className="col w-full">
                          <p className="text-lg font-bold">İletişim Bilgileri</p>
                          <p className="text-sm text-gray-500 mt-2">
                            Footer kısmında son kullanıcılarınız ile iletişim bilgisi olarak paylaşılacaktır.
                          </p>
                        </div>
                        <div className="col">
                          <button
                            onClick={() => setIsEditableFields(!IsEditableFields)}
                            className={`btn ${IsEditableFields ? " invisible" : ""}`}
                          >
                            Düzenle
                          </button>
                        </div>
                      </div>

                      <div className="grid sm:grid-cols-2 gap-4 mt-8">
                        <div className="col mb-8 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Telefon Numarası</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p>{FormInfo.phone || myWorkAreaInfoList?.phone}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <div className="mt-1.5">
                              <input
                                type="tel"
                                name="phone"
                                ref={inputPhoneRef}
                                className={`w-full ${Errors?.phone?.length ? "!border-error-500" : ""}`}
                                defaultValue={FormInfo.phone || myWorkAreaInfoList?.phone}
                                onChange={setForm}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col mb-8 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Mail Adresi</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p className="truncate">{inputEmailRef.current?.value}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <div className="mt-1.5">
                              <input
                                type="email"
                                name="email"
                                ref={inputEmailRef}
                                className={`w-full ${Errors?.email?.length ? "!border-error-500" : ""}`}
                                defaultValue={FormInfo.email || myWorkAreaInfoList?.email}
                                onChange={setForm}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col mb-8 col-start-1 col-end-3 last:mb-0">
                          <p className="text-sm font-semibold pb-0.5">Adres</p>
                          {!IsEditableFields && (
                            <div className="text-gray-500">
                              <p>{FormInfo.address || AdressValue}</p>
                            </div>
                          )}
                          {IsEditableFields && (
                            <>
                              <div className="grid sm:grid-cols-3 gap-4 mt-1.5">
                                <div
                                  ref={countryWrapper}
                                  className="country-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    placement="bottomStart"
                                    placeholder={""}
                                    loading={!!countriesLoading}
                                    value={ActiveCountry}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Countries}
                                    searchable={Countries.length > 7}
                                    container={countryWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveCountry(value)
                                      setActiveCity(null)
                                      setActiveDistrict(null)
                                    }}
                                  />
                                </div>

                                <div
                                  ref={cityWrapper}
                                  className="city-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    placement="bottomStart"
                                    placeholder={ActiveCountry ? "" : "Önce ülke seçiniz"}
                                    loading={!!citiesLoading}
                                    value={ActiveCity === null ? "" : ActiveCity}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Cities}
                                    searchable={Cities.length > 7}
                                    container={cityWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveCity(value)
                                    }}
                                  />
                                </div>

                                <div
                                  ref={districtWrapper}
                                  className="district-wrapper relative"
                                >
                                  <SelectPicker
                                    block
                                    // disabled={!ActiveCity}
                                    placement="bottomStart"
                                    placeholder={ActiveCity ? "" : "Önce il seçiniz"}
                                    loading={!!districtsLoading}
                                    className={ActiveCity ? "" : "pointer-events-none"}
                                    value={ActiveDistrict === null ? "" : ActiveDistrict}
                                    locale={localization?.TR?.PickerLocaleType}
                                    menuMaxHeight={164}
                                    cleanable={false}
                                    data={Districts}
                                    searchable={Districts.length > 7}
                                    container={districtWrapper.current}
                                    onSelect={(value, item) => {
                                      setActiveDistrict(value)
                                    }}
                                  />
                                </div>

                                <textarea
                                  name="address"
                                  className="col-start-1 col-end-4 text-lg text-gray-500"
                                  defaultValue={FormInfo.address || AdressValue}
                                  onChange={setForm}
                                />
                              </div>
                              <div className="button-area flex justify-end gap-4 mt-8">
                                <button
                                  onClick={() => {
                                    setIsEditableFields(!IsEditableFields)
                                    setFormInfo({})
                                    setErrors({})
                                  }}
                                  className="btn outline"
                                >
                                  Vazgeç
                                </button>
                                <button
                                  onClick={(e) => saveButton(e)}
                                  className={`btn`}
                                  type="submit"
                                  disabled={BtnDisabled}
                                >
                                  Kaydet
                                </button>
                              </div>
                            </>
                          )}
                          <div className={`map-box ${IsEditableFields ? "invisible h-0" : "mt-4"}`}>
                            {MyWorkAreaLoaction.lat && (
                              <div style={{ height: "250px", width: "100%" }}>
                                <GoogleMap
                                  bootstrapURLKeys={{ key: "AIzaSyCYRTHPzopuYqCowEbOGQquCwZxfhrzhpc" }}
                                  defaultCenter={MyWorkAreaLoaction}
                                  yesIWantToUseGoogleMapApiInternals
                                  defaultZoom={11}
                                >
                                  <Marker
                                    lat={MyWorkAreaLoaction.lat}
                                    lng={MyWorkAreaLoaction.lng}
                                  >
                                    <img
                                      src={myWorkAreaInfoList?.logoUrl}
                                      className="w-full h-full object-cover"
                                      alt="my-hotel-logo"
                                    />
                                  </Marker>
                                </GoogleMap>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {getSettingsData && !getSettingsLoading && !getSettingsError && (
                  <BorderBox className="gap-6 p-8">
                    <div className="box-header flex items-center gap-2.5 pb-4 border-b border-gray-200">
                      <div className="box-article flex flex-col gap-2 w-full">
                        <p className="text-lg font-bold">Çağrı Merkezi</p>
                        <p className="text-sm text-gray-500">
                          Çağrı merkezi hizmetinize ait bilgileri düzenleyin.
                        </p>
                      </div>
                      <div className={`box-action-buttons ${CallCenterEditable ? "hidden" : "block"}`}>
                        <button onClick={() => setCallCenterEditable(true)} className="btn">Düzenle</button>
                      </div>
                    </div>
                    <div className="box-body flex flex-col">
                      {!CallCenterEditable && !CallCenterPropertyList?.callCenterDescription?.PropertyValue && !CallCenterPropertyList?.callCenterPhone?.PropertyValue && (
                        <div className="empty-box flex items-start gap-3 p-4 bg-gray-50 text-gray-500 rounded-lg overflow-hidden">
                          <Icon className="shrink-0" name="info" size={24} color="#667085" />
                          <div className="flex flex-col gap-2">
                            <p className="text-lg font-bold">Çağrı merkezi bilgilerinizi girmediniz.</p>
                            <p className="text-base">Müşterilerinize çağrı merkezi hizmeti sunmak istiyorsanız düzenleme modundan bilgilerinizi girebilirsiniz.</p>
                          </div>
                        </div>
                      )}
                      {(CallCenterEditable || CallCenterPropertyList?.callCenterDescription?.PropertyValue || CallCenterPropertyList?.callCenterPhone?.PropertyValue) && (
                        <>
                          <form className="info-box flex flex-col gap-8">
                            <RadioGroup className="flex gap-8" name="radio-group-inline" inline defaultValue="showPrimaryOnFooter">

                              <div className="input-area text-sm flex flex-col gap-1">
                                <label htmlFor={`call-center-primary`} className="flex flex-nowrap text-nowrap font-semibold">Birincil Çağrı Merkezi Numarası
                                </label>
                                <input
                                  id="call-center-primary"
                                  disabled={!CallCenterEditable}
                                  name="phone"
                                  type="tel"
                                  maxLength={17}
                                  placeholder="Numara girin"
                                  value={CallCenterPropertyList?.callCenterPhone?.PropertyValue}
                                  onChange={e => setCallCenterPropertyList({
                                    ...CallCenterPropertyList,
                                    callCenterPhone: {
                                      ...CallCenterPropertyList?.callCenterPhone,
                                      Status: e?.target?.value?.length ? 1 : 0,
                                      PropertyValue: e?.target?.value
                                    }
                                  })}
                                />
                                <Radio
                                  disabled={!CallCenterEditable}
                                  name="myGroupName"
                                  value={"showPrimaryOnFooter"}
                                  className="text-gray-500"
                                >İletişim bilgisi olarak kullan ve footer alanında göster.
                                </Radio>
                              </div>
                              <div className="input-area text-sm flex flex-col gap-1">
                                <label htmlFor={`call-center-secondary`} className="flex flex-nowrap text-nowrap font-semibold">İkincil Çağrı Merkezi Numarası
                                  <span className="font-light text-gray-400 ml-2">OPSİYONEL</span>
                                </label>
                                <input
                                  id="call-center-secondary"
                                  disabled={true}
                                  name="phone"
                                  type="tel"
                                  placeholder="Numara girin"
                                  value="444 0 abc"
                                />
                                <Radio
                                  disabled={true}
                                  name="myGroupName"
                                  value="showSecondaryOnFooter"
                                  className="text-gray-500"
                                >İletişim bilgisi olarak kullan ve footer alanında göster.
                                </Radio>
                              </div>

                            </RadioGroup>
                            <div className="flex flex-col text-sm gap-4">
                              <p className="flex flex-nowrap text-nowrap font-semibold">Çağrı Merkezi Metni</p>
                              <div className="flex items-center gap-2">
                                <Icon className="shrink-0" name="info" size={16} color="#667085" />
                                <p className="text-sm">Çağrı merkezi çalışma saatlerinizi ve numaranızı metin içerisinde kullanıcılarınıza anlatın.</p>
                              </div>
                              <TextareaInput
                                readOnly={!CallCenterEditable}
                                disabled={!CallCenterEditable}
                                title={""}
                                resize={"none"}
                                value={CallCenterPropertyList?.callCenterDescription?.PropertyValue}
                                onChange={e => setCallCenterPropertyList({
                                  ...CallCenterPropertyList,
                                  callCenterDescription: {
                                    ...CallCenterPropertyList?.callCenterDescription,
                                    Status: e?.target?.value?.length ? 1 : 0,
                                    PropertyValue: e?.target?.value
                                  }
                                })}
                                placeholder="Örnek metin: Ayrıntılı bilgi ve rezervasyon için 444 4 444 nolu Müşteri Hizmetlerini arayabilirsiniz. Müşteri hizmetlerimiz hafta içi 09:30-18:00 (GMT+3) , hafta sonu 10:00-13:00 (GMT+3) arası hizmet vermektedir."
                                rows={6}
                              />
                            </div>
                          </form>
                          <div className={`${!CallCenterEditable ? "hidden" : "flex justify-end gap-4"} mt-4`}>
                            <button onClick={() => setCallCenterEditable(false)} className="btn outline">Vazgeç</button>
                            <button onClick={saveCallCenterProperties} className="btn">Kaydet</button>
                          </div>
                        </>
                      )}

                    </div>
                  </BorderBox>
                )}

                {getSettingsError && !getSettingsLoading && (
                  <ErrorMessage className="mt-2">Çağrı Merkezine ait bilgiler yuklenirken bir hata ile karsilasildi.</ErrorMessage>
                )}

                {getSettingsLoading && (
                  <div className="flex flex-col page-container xl:w-9/12 w-full transition-[width] duration-500 p-10">
                    <div className="flex bg-white border border-solid border-gray-200 justify-between px-12 py-4 mb-10 gap-24 w-full h-[300px]">
                      <div className="flex flex-col w-1/2 justify-center">
                        <div className="animate-pulse space-y-3 my-7">
                          <div className="h-2 bg-slate-200 rounded w-1/3" />
                          <div className="h-2 bg-slate-200 rounded" />
                        </div>
                        <div className="animate-pulse space-y-3 my-7">
                          <div className="h-2 bg-slate-200 rounded w-1/3" />
                          <div className="h-2 bg-slate-200 rounded" />
                        </div>
                      </div>
                      <div className="flex flex-col w-1/2 justify-center">
                        <div className="animate-pulse space-y-3 my-7 justify-end">
                          <div className="h-10 bg-slate-200 rounded w-1/3 float-right" />
                        </div>
                        <div className="animate-pulse space-y-3 my-7">
                          <div className="h-2 bg-slate-200 rounded w-1/3" />
                          <div className="h-2 bg-slate-200 rounded" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
  </main>
    </>
  )
}

export default MyHotel
