import React, { useState } from "react"
import { Link } from "react-router-dom"
import Cookies from "universal-cookie"

//components
import { BorderBox } from "components"
import { Tag, Input, IconButton, Toggle } from "rsuite"

// helpers
import { setSettings } from "helpers/setSettings"

const Seo = (props) => {
  let { seoSettings, setSeoSettings } = props

  const cookies = new Cookies()
  const DomainName = cookies.get("associatedDomain")?.domainName

  const [DomainUrl] = useState(`${window.location.protocol}//${DomainName}`)
  const [KeywordTyping, setKeywordTyping] = React.useState(false)
  const [KeywordInputValue, setKeywordInputValue] = React.useState("")

  const removeTag = (tag) => {
    const nextTags = seoSettings.keywords.filter((item) => item !== tag)
    setSeoSettings({
      ...seoSettings,
      keywords: nextTags,
    })
  }

  const addTag = () => {
    const nextTags = KeywordInputValue ? [...seoSettings?.keywords, KeywordInputValue] : seoSettings?.keywords
    setSeoSettings({
      ...seoSettings,
      keywords: nextTags,
    })
    setKeywordTyping(false)
    setKeywordInputValue("")
  }

  const handleButtonClick = () => {
    setKeywordTyping(true)
  }

  const renderInput = () => {
    if (KeywordTyping) {
      return (
        <Input
          className="tag-input !text-gray-500 !font-medium !text-sm !h-6 !inline-block bg-gray-100 !shadow-none !outline-0 !border-none !caret-gray-500"
          size="sm"
          style={{ width: 70 }}
          autoFocus
          value={KeywordInputValue}
          onChange={setKeywordInputValue}
          onBlur={addTag}
          onPressEnter={addTag}
        />
      )
    }

    return (
      <IconButton
        className="tag-add-btn rounded-2xl bg-blue-50"
        onClick={handleButtonClick}
        appearance="ghost"
        size="xs"
      >
        Ekle
      </IconButton>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-sm text-gray-800 font-medium">Google ön izleme</p>

        <BorderBox className="items-start gap-0.5 p-3">
          <h3 className="font-semibold text-lg text-[#16459E]">
            {seoSettings.title.startsWith("/") ? seoSettings.title.substring(1) : seoSettings.title}
          </h3>
          <Link
            className="inline-block"
            to={`${DomainUrl}${
              seoSettings?.path && seoSettings?.path?.startsWith("/") ? seoSettings?.path : `/${seoSettings?.path}`
            }`}
            target="_blank"
          >
            <p className="text-sm text-[#27AE60]">
              {DomainUrl}
              {seoSettings?.path && seoSettings?.path?.startsWith("/") ? seoSettings?.path : `/${seoSettings?.path}`}
            </p>
          </Link>
          <p className="text-gray-600 text-sm">{seoSettings?.description}</p>
        </BorderBox>
      </div>

      <div className="flex flex-col gap-4">
        {/*title*/}
        <div className="flex flex-col gap-1.5">
          <p className="text-sm text-gray-800 font-medium">SEO sayfa başlığı</p>
          <input
            type="text"
            name="title"
            value={seoSettings.title}
            onChange={(e) => setSettings(e, true, seoSettings).then((res) => setSeoSettings(res))}
          />
        </div>

        {/*description*/}
        <div className="flex flex-col gap-1.5">
          <p className="text-sm text-gray-800 font-medium">SEO sayfa açıklaması</p>
          <textarea
            className="h-32 resize-none"
            name="description"
            value={seoSettings.description}
            onChange={(e) => setSettings(e, true, seoSettings).then((res) => setSeoSettings(res))}
            placeholder="Bu sayfayı özgün kılan nedir? İşletmeniz ve bu sayfanın içeriği hakkında bilgi verin."
          />
        </div>

        {/*keywords*/}
        <div className="flex flex-col gap-1.5">
          <p className="text-sm text-gray-800 font-medium">Anahtar kelimeler</p>
          <BorderBox className="p-3 pb-14">
            <div className="flex flex-wrap items-center gap-2">
              {seoSettings?.keywords &&
                seoSettings?.keywords.map((item, index) => (
                  <Tag
                    className="!text-sm text-gray-500 font-medium bg-gray-100 rounded-2xl px-2.5 py-0.5"
                    key={index}
                    closable
                    onClose={() => removeTag(item)}
                  >
                    {item}
                  </Tag>
                ))}
              {renderInput()}
            </div>
          </BorderBox>
        </div>

        {/*CanonicalUrl*/}
        <div className="flex flex-col gap-1.5">
          <p className="text-sm text-gray-800 font-medium">Canonical URL</p>
          <input
            type="text"
            name="canonicalUrl"
            placeholder={`${DomainUrl}${
              seoSettings?.path && seoSettings?.path?.startsWith("/") ? seoSettings?.path : `/${seoSettings?.path}`
            }`}
            value={seoSettings.canonicalUrl}
            onChange={(e) => setSettings(e, true, seoSettings).then((res) => setSeoSettings(res))}
          />
        </div>

        {/*CanonicalUrl*/}
        <div className="flex flex-row-reverse gap-2">
          <p className="text-sm text-gray-800 font-medium">Bu sayfa arama motorları sonuçlarında görünsün.</p>
          <Toggle
            size="md"
            checked={seoSettings.index}
            onChange={(e) =>
              setSettings(
                {
                  target: {
                    name: "index",
                    value: e,
                  },
                },
                true,
                seoSettings,
              ).then((res) => setSeoSettings(res))
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Seo
