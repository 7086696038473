import React, { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { PreviewView, Icon } from "components"
import { useLocation } from "react-router"
// show aside when it's not contains on the list
import pageMainAsideList from "layouts/Aside/MainAside/blacklist.json"
// utils
/*import { isDevEnv, isTestEnv } from "utils/environmentsCheck"*/

const Aside = (Config) => {
  const location = useLocation()

  // refs
  const mainAside = useRef()

  // states
  const [IsAdmin] = useState(!!Config.UserInfo?.role?.includes("paneladmin"))
  const [DomainType, setDomainType] = useState(Config.AssociatedDomains?.domainType)
  const [IsOldB2C, setIsOldB2C] = useState(Config.AssociatedDomains?.oldB2C)
  const [IsOtel, setIsOtel] = useState(DomainType === "Otel")
  const [IsB2C, setIsB2C] = useState(DomainType === "B2C")
  const [IsCorporate, setIsCorporate] = useState(DomainType === "Corporate")
  const [preview, setPreview] = useState(false)
  const [ShowSideBar, setShowSideBar] = useState(false)
  const [AsideMoverStyle, setAsideMoverStyle] = useState({
    top: "24px",
    left: "24px",
    width: "100%",
    opacity: 0,
    borderRadius: "8px",
    backgroundColor: "#F9FAFB",
  })

  // effects
  useEffect(() => {
    let hideSidebar = pageMainAsideList.blackList.find((page) => location.pathname.includes(page))
    setShowSideBar(!!!hideSidebar)

    if (ShowSideBar) {
      let childs = Object.values(mainAside.current.querySelectorAll("a")),
        activeLink = childs?.find((child) => child?.classList?.value.includes("active"))

      childs.map((el) => {
        let getBoundingClientRect = el.getBoundingClientRect()
        if (el.classList.value.includes("active")) {
          setAsideMoverStyle({
            ...AsideMoverStyle,
            width: getBoundingClientRect.width,
            height: getBoundingClientRect.height,
            left: el.offsetLeft,
            top: el.offsetTop,
            opacity: 1,
          })
        }

        // events
        el.addEventListener("mouseenter", (e) => {
          setAsideMoverStyle({
            ...AsideMoverStyle,
            top: el.offsetTop,
            left: el.offsetLeft,
            height: el.clientHeight,
            width: el.clientWidth,
            opacity: 1,
          })
        })
        return true
      })

      if (activeLink) {
        mainAside.current.addEventListener("mouseleave", () => {
          setAsideMoverStyle({
            ...AsideMoverStyle,
            top: activeLink?.offsetTop,
            left: activeLink?.offsetLeft,
            height: activeLink?.clientHeight,
            width: activeLink?.clientWidth,
            opacity: 1,
          })
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, ShowSideBar])


  useEffect(() => {
    const domainType = Config.AssociatedDomains?.domainType
    setDomainType(domainType);
    setIsOldB2C(Config.AssociatedDomains?.oldB2C);
    setIsOtel(domainType === "Otel")
    setIsB2C(domainType === "B2C")
    setIsCorporate(domainType === "Corporate")
  }, [Config])

  return (
    <>
      {ShowSideBar && (
        <>
          <aside
            ref={mainAside}
            className="aside border-r border-r-gray-200 p-6 z-10"
          >
            <nav>
              <NavLink
                to="/"
                hidden={IsAdmin}
              >
                <Icon
                  color="currentColor"
                  name="home"
                />
                <span>Ana Sayfa</span>
              </NavLink>

              <NavLink
                to="/reports"
                hidden={IsAdmin || IsB2C || IsOtel}
              >
                <Icon
                  color="currentColor"
                  name="pie-chart"
                />
                <span>Raporlar</span>
              </NavLink>
              <div className="h-[1px] w-full bg-transparent mt-[22px] mb-6" />

              <NavLink
                to="/my-hotel"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsB2C || IsAdmin || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="key"
                />
                <span>Otelim</span>
              </NavLink>
              <NavLink
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsCorporate}
                to="/my-reservations"
              >
                <Icon
                  color="currentColor"
                  name="book-open"
                />
                <span>Rezervasyonlarım</span>
              </NavLink>
              <NavLink
                to="/corporate-information"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsOtel || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="info"
                />
                <span>Kurumsal Bilgiler</span>
              </NavLink>
              <NavLink
                to="/contracts"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsOtel || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="list"
                />
                <span>Sözleşmeler</span>
              </NavLink>
              <NavLink
                to="/page-manager"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsCorporate || IsOtel || IsOldB2C}
              >
                <Icon
                  color="currentColor"
                  name="layout"
                />
                <span>Sayfa Yöneticisi</span>
              </NavLink>
              <NavLink
                to="/integration-settings"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="external-link"
                />
                <span>Yayınlama</span>
              </NavLink>
              <NavLink
                to="/account-management"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={!IsAdmin || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="external-link"
                />
                <span>Müşteri Yönetimi</span>
              </NavLink>
              <NavLink
                to="/domain-dns"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={!IsAdmin || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="external-link"
                />
                <span>Domain ve DNS Ayarları</span>
              </NavLink>
              <div
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                className={`${IsAdmin || IsCorporate ? "hidden" : ""} flex flex-row items-center p-4 mb-1 gap-[18px]`}
                onClick={() => setPreview(true)}
              >
                <Icon
                  name="eye"
                  color="#475467"
                />
                <p className=" text-gray-600 font-medium cursor-pointer">Ön İzleme</p>
              </div>
              <NavLink
                to="/miles-smiles"
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                hidden={IsAdmin || IsOtel || IsCorporate}
              >
                <Icon
                  color="currentColor"
                  name="pocket"
                />
                <span>Sadakat Programı</span>
              </NavLink>
              {/* @todo available path parameters will be added here */}
              <NavLink
                to="/rules"
                hidden={IsAdmin || IsB2C || IsOtel}
              >
                <Icon
                  color="currentColor"
                  name="list"
                />
                <span>Kurallar</span>
              </NavLink>
              {/* @todo available path parameters will be added here */}
              <NavLink
                to="/defines"
                hidden={IsAdmin || IsB2C || IsOtel}
              >
                <Icon
                  color="currentColor"
                  name="info"
                />
                <span>Tanımlar</span>
              </NavLink>
              {/* @todo available path parameters will be added here */}
              <NavLink
                to="/settings"
                hidden={IsAdmin}
              >
                <Icon
                  color="currentColor"
                  name="settings"
                />
                <span>Ayarlar</span>
              </NavLink>
              {/* @todo available path parameters will be added here */}
              <NavLink
                // @todo only page manager is hidden for now. It will be changed, add corporate role check in here.
                to={IsOtel ? "/view-settings" : "/theme-settings/configuration"}
                hidden={IsAdmin}
              >
                <Icon
                  color="currentColor"
                  name="image"
                />
                <span>Görünüm Ayarları</span>
              </NavLink>
              {/* <div className={`${IsAdmin ? "hidden" : ""} h-[1px] w-full bg-gray-200 mt-12 mb-10`} />

              <NavLink
                to="/marketplace"
                hidden={IsAdmin || !isDevEnv}
              >
                <Icon
                  color="currentColor"
                  name="marketplace"
                />
                <div>
                  <span>
                    EVA Mağaza
                    <span className="text-success-500 bg-success-50 rounded-2xl ml-2 px-[10px] py-1">Yeni</span>
                  </span>
                  <p className="text-sm text-gray-500 mt-[6px] whitespace-nowrap font-normal">Find the best solution for you.</p>
                </div>
              </NavLink> */}
              <span
                style={{ ...AsideMoverStyle }}
                className="mover absolute pointer-events-none transition-all duration-300 -z-[1]"
              />
            </nav>
          </aside>
          {preview && <PreviewView setPreview={setPreview} />}
        </>
      )}
    </>
  )
}

export default Aside
