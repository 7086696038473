import axios from "axios"
import { logout } from "../utils/logout"
import { getUrl } from "./remoteHost"
import TokenCookieHelper from "./tokenCookieHelper";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${TokenCookieHelper.getTokenCookie()}`,
  },
})

instance.interceptors.request.use((config) => ({
  ...config,
  url: getUrl(config.url),
}))

instance.interceptors.response.use(
  async (response) => {
    if (response.status === 401) await logout()
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) await logout()
    return Promise.reject(error)
  },
)

export default instance
