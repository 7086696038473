import {associatedDomainsRequested} from "redux/actions/associatedDomains";

//cookies
import Cookies from "universal-cookie"
const cookies = new Cookies()

export const domainChange = (dispatch) => {
	cookies.remove("associatedDomain")
	cookies.remove("domainList")
	dispatch(associatedDomainsRequested())
}