import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/myWorkAreaInfo"

import { MY_WORKAREA_INFO_REQUESTED } from "redux/types"
import { API_GET } from "helpers/api"

function* watchMyWorkAreaInfo() {
  yield takeLatest(MY_WORKAREA_INFO_REQUESTED, MyWorkAreaInfo)
}

function* MyWorkAreaInfo(action) {
  try {
    const res = yield call(apiMyWorkAreaInfo, action.payload)
    if (res.data.success) {
      yield put(actions.MyWorkAreaSucceed(res.data.result))
    } else {
      yield put(actions.MyWorkAreaFailed())
    }
  } catch (err) {
    yield put(actions.MyWorkAreaFailed())
  }
}

const apiMyWorkAreaInfo = (payload) => {
  return API_GET(`${process.env.REACT_APP_PANEL_DOMAIN}/api/hotel/getdetail/${payload}`)
}

export default function* GetMyWorkAreaInfo() {
  yield fork(watchMyWorkAreaInfo)
}
