import Cookies from "universal-cookie"
const cookies = new Cookies()


const TokenCookieHelper = {
	setTokenCookie: (tokenData, COOKIE_LIMIT = 2000) => {
		const tokenParts = [];
		for (let i = 0; i < tokenData.access_token.length; i += COOKIE_LIMIT) {
			tokenParts.push(tokenData.access_token.slice(i, i + COOKIE_LIMIT));
		}

		tokenParts.forEach((part, index) => {
			cookies.set(`access_token.${index + 1}`, part, {
				expires: tokenData.expires_in,
			});
		});
	},
	getTokenCookie: () => {
		let fullToken = "";
		let index = 1;

		while (true) {
			const part = cookies.get(`access_token.${index}`);
			if (!part) break;
			fullToken += part;
			index++;
		}
		if (!fullToken) return null;
		return fullToken;
	},
	deleteTokenCookie: () => {
		let index = 1;
		let removedCookies = [];

		while (true) {
			const cookieName = `access_token.${index}`;
			if (!cookies.get(cookieName)) break;
			cookies.remove(cookieName);
			removedCookies.push(cookieName);
			index++;
		}
		return removedCookies;
	}
};

export default TokenCookieHelper