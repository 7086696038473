export const sizeCalculator = (size) => {
	if (size){
		let resultSize = size
		if (resultSize > 100000) {
			resultSize = resultSize / 1000000
			resultSize = resultSize.toFixed(2) + " MB"
		} else if (resultSize >= 1000) {
			resultSize = resultSize / 1000
			resultSize = (resultSize + "").split(".")[0] + " KB"
		} else if (resultSize < 1000) {
			resultSize = resultSize / 1000
			resultSize = resultSize + " bytes"
		}

		return resultSize
	}
	return 0
}