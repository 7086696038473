import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

// import Swiper core and required modules
import { Navigation, A11y, Parallax, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import moment from "moment"

// images
import Logo from "assets/img/logo.png"
import TR_logo from "assets/img/flags/small/TR.png"
import tursabImg from "./img/tursab_belge.png"
import maestro from "./img/credit_cards/maestro.png"
import master from "./img/credit_cards/master.png"
import stripe from "./img/credit_cards/stripe.png"
import visa from "./img/credit_cards/visa.png"
import banner1 from "./img/banner-img-1.jpg"
import holiday1 from "./img/holiday_themes/1.jpg"
import holiday2 from "./img/holiday_themes/2.jpg"
import holiday3 from "./img/holiday_themes/3.jpg"
import holiday4 from "./img/holiday_themes/4.jpg"
import popular_area1 from "./img/popular_areas/1.jpg"
import popular_area2 from "./img/popular_areas/2.jpg"
import popular_area3 from "./img/popular_areas/3.jpg"
import search_area from "./img/search-area-bg.jfif"
import stack from "./img/stack.png"

// Styles

// Components
import Icon from "components/Icon"

// utils
import { hexIsLight } from "utils/hexIsLight"
import { isInViewport } from "utils/isInViewport"

const LivePreview = ({ config: { ThemeConfig, ThemeConfigBeauty } }) => {
  // Refs
  const searchAreaImg_ref = useRef()
  const header_ref = useRef()
  const lpBody = useRef()

  // States
  const [ThemeLogo, setThemeLogo] = useState(Logo)
  const [Offset, setOffset] = useState(0)

  // Selectors
  const Device = useSelector((state) => state.DisplayDevice)

  // Func
  const handleScroll = (event) => {
    if (event) setOffset(event.currentTarget.scrollTop)
  }

  // Effects
  useEffect(() => {
    if (ThemeConfig?.logoValues?.src) {
      setThemeLogo(ThemeConfig.logoValues.src)
    } else {
      setThemeLogo(Logo)
    }
  }, [ThemeConfig])

  // Attach the scroll listener to the body
  useEffect(() => {
    const body = lpBody.current
    body.addEventListener("scroll", handleScroll)
  }, [])

  // animations
  useEffect(() => {
    if (Offset && searchAreaImg_ref && header_ref) {
      if (isInViewport(searchAreaImg_ref.current)) {
        searchAreaImg_ref.current.style.backgroundPosition = `50% ${70 - Offset / 30}%`
      }

      if (Offset > 380) {
        header_ref.current.classList.add("sticky")
      } else {
        header_ref.current.classList.remove("sticky")
      }
    }
  }, [Offset, searchAreaImg_ref, header_ref])

  return (
    <>
      {/*<pre className="w-full p-2 overflow-hidden shrink-0" style={{ backgroundColor: "#1b1b1b", color: "#83ba52" }}>{ThemeConfigBeauty}</pre>
      <pre className="absolute right-0 top-0 p-2 overflow-hidden flex flex-col index-10" style={{ backgroundColor: "#1b1b1b", color: "#83ba52" }}>
        <span className="inline-block w-12 h-12" style={{backgroundColor: ThemeConfig.displaySettings.buttonColor}}/>
        <span className="inline-block w-12 h-12" style={{backgroundColor: ThemeConfig.displaySettings.linkColor}}/>
      </pre>*/}

      <div
        ref={lpBody}
        className={`lp-body w-full h-full${Device.id === 0 ? " mobile" : ""} bg-white`}
        style={{
          fontFamily: ThemeConfig.displaySettings.fontFamily,
          overflow: "auto",
          height: Device.id === 0 ? "calc(100% - (56px + 84px))" : "",
          margin: "auto",
          width: Device.id === 0 ? "375px" : "",
        }}
        onScroll={handleScroll}
      >
        {Device.id === 1 && (
          <>
            <div
              ref={header_ref}
              className="lp-header relative z-20 bg-white"
            >
              <div className="main-header border-b border-gray-200 py-5">
                <div className="lp-container flex items-center justify-between">
                  <div className="logo-wrapper max-w-[140px]">
                    <img
                      className="object-cover max-h-[33px]"
                      src={ThemeLogo}
                      alt={ThemeConfig?.logoValues?.name}
                    />
                  </div>
                  <div className="navbar">
                    <ul className="flex items-center">
                      <li className="ml-8 first:ml-0">
                        <div className="language-selector flex items-center">
                          <img
                            className="mr-1"
                            src={TR_logo}
                            alt=""
                          />
                          <span>Türkçe / TL</span>
                        </div>
                      </li>
                      <li className="ml-8 first:ml-0">Yardım</li>
                      <li className="ml-8 first:ml-0">Giriş Yap</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-header">
              <div className="lp-container">
                <nav>
                  <ul className="flex items-center text-gray-500 font-medium">
                    {[
                      { text: "Otel", icon: "building" },
                      { text: "Uçak", icon: "plane" },
                      { text: "Tur", icon: "suitcase" },
                      { text: "Transfer", icon: "car" },
                      { text: "Paketle Kazan", icon: "special_days" },
                    ].map((item, key) => {
                      return (
                        <li
                          key={key}
                          className={`flex items-center justify-center text-center py-4 px-2 ml-8 first:ml-0 ${
                            key === 0 ? " font-semibold border-b-2 min-w-[120px] cursor-pointer" : "cursor-not-allowed"
                          }`}
                          style={
                            key === 0
                              ? {
                                  color: ThemeConfig.displaySettings.linkColor,
                                  borderColor: ThemeConfig.displaySettings.linkColor,
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                              : {
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                          }
                        >
                          <Icon
                            className="mr-2"
                            name={item.icon}
                            size="20"
                            color={`${key === 0 ? ThemeConfig.displaySettings.linkColor : "#667085"}`}
                          />
                          <span>{item.text}</span>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="lp-main">
              <div className="travel-planner">
                <div
                  ref={searchAreaImg_ref}
                  className={`search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            after:absolute after:inset-0 after:z-10 after:bg-gray-800 after:opacity-30`}
                  style={{ backgroundImage: `url(${search_area})` }}
                >
                  <div className="lp-container relative z-20 py-16">
                    <div className="text-wrapper text-white">
                      <div className="heading text-3xl font-bold drop-shadow-sm">Sonraki konaklamanızı bulun.</div>
                      <div className="text text-xl font-medium drop-shadow-sm">
                        Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                      </div>
                    </div>
                    <div
                      className="searchbar flex items-center bg-white shadow-_2xl overflow-hidden !transition-all p-4 mt-6"
                      style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                    >
                      {[
                        { placeholder: "Nereye gidiyorsun?", icon: "search" },
                        { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                        { placeholder: "2 misafir, 1 oda", icon: "person" },
                      ].map((v, k) => {
                        return (
                          <div
                            key={k}
                            className={`input-wrapper ${
                              k === 2 ? "w-7/12" : "w-full"
                            } relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all ml-4 first:ml-0`}
                            style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                          >
                            <Icon
                              className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                              name={v.icon}
                              size="20"
                              color={`${k === 2 ? "#000" : "#667085"}`}
                            />
                            <input
                              type="text"
                              className={`w-full border-none !pl-9 disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                                k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                              }`}
                              placeholder={v.placeholder}
                              disabled
                            />
                          </div>
                        )
                      })}
                      <button
                        className={`btn whitespace-nowrap px-7 border-0 cursor-not-allowed !transition-all ml-4`}
                        style={{
                          backgroundColor: ThemeConfig.displaySettings.buttonColor,
                          borderRadius: ThemeConfig.displaySettings.borderRadius,
                          color: ThemeConfig.displaySettings?.buttonColor
                            ? hexIsLight(ThemeConfig.displaySettings.buttonColor)
                              ? "black"
                              : "white"
                            : "",
                        }}
                      >
                        Otel ara
                      </button>
                    </div>
                  </div>
                </div>
                <div className="features py-6 mb-12">
                  <div className="lp-container flex items-center">
                    {[
                      { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                      { text: "Güvenli online alışveriş", icon: "shield" },
                      { text: "18 aya varan taksitler", icon: "credit-card" },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="features-box w-full flex items-center justify-center text-center py-4"
                        >
                          <Icon
                            className="mr-2"
                            name={v.icon}
                            size="24"
                            color="#475467"
                          />
                          <span className="font-medium text-gray-500">{v.text}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              {/*Banner Area*/}
              <div className="banner-area">
                <div className="banner-navigtion">
                  <ul className="flex items-center justify-center text-gray-500 font-medium text-base">
                    {["Yaz Fırsatları", "Erken Rezervasyon", "Etkinlikler", "Ödeme Kolaylıkları"].map((v, k) => {
                      return (
                        <li
                          key={k}
                          className={`${
                            k === 0 ? "border shadow-buttonSm cursor-pointer" : "cursor-not-allowed"
                          } overflow-hidden !transition-all py-2.5 px-4 ml-8`}
                          style={
                            k === 0
                              ? {
                                  borderColor: ThemeConfig.displaySettings.buttonColor,
                                  color: ThemeConfig.displaySettings.buttonColor,
                                  borderRadius: ThemeConfig.displaySettings.borderRadius,
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                              : {
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                          }
                        >
                          {v}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {/*Banner Slider*/}
                <div className="banner-content mt-6">
                  <div className="lp-container relative flex items-center z-10 !transition-all">
                    <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition banner-swiper-prev">
                      <Icon
                        name="arrow-left"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                    <Swiper
                      style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                      className="overflow-hidden transition-all"
                      modules={[Navigation, A11y, Parallax]}
                      spaceBetween={0}
                      slidesPerView={1}
                      parallax={true}
                      speed={1200}
                      pagination={{
                        el: ".swiper-pagination",
                        clickable: true,
                        dynamicBullets: true,
                      }}
                      navigation={{
                        prevEl: ".banner-swiper-prev",
                        nextEl: ".banner-swiper-next",
                        disabledClass: "gray-200 pointer-events-none opacity-40",
                      }}
                    >
                      {[1, 2].map((v, k) => {
                        return (
                          <SwiperSlide
                            className={"overflow-hidden"}
                            key={k}
                          >
                            <img
                              className="absolute t-0 l-0 -z-10 h-full w-full object-cover"
                              data-swiper-parallax="35%"
                              data-swiper-parallax-opacity="1"
                              src={banner1}
                              alt=""
                            />
                            <div className="text-area w-9/12 py-20 px-10">
                              <p
                                className="text-5xl font-semibold text-white drop-shadow-sm"
                                data-swiper-parallax-x="15%"
                                data-swiper-parallax-opacity="0"
                              >
                                Yaz fırsatları devam ediyor! Kaçırmadan yakala
                              </p>
                              <button
                                className="btn border-none px-8 mt-6 cursor-not-allowed"
                                data-swiper-parallax-x="25%"
                                data-swiper-parallax-y="-10"
                                data-swiper-parallax-opacity="0"
                                style={{
                                  backgroundColor: ThemeConfig.displaySettings.buttonColor,
                                  borderRadius: ThemeConfig.displaySettings.borderRadius,
                                  color: ThemeConfig.displaySettings?.buttonColor
                                    ? hexIsLight(ThemeConfig.displaySettings.buttonColor)
                                      ? "black"
                                      : "white"
                                    : "",
                                }}
                              >
                                Fırsatları gör
                              </button>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition banner-swiper-next">
                      <Icon
                        name="arrow-right"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Konaklama Seçenekleri*/}
              <div className="accommodation_options my-12">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-2xl">Konaklama seçenekleri</p>

                  <div className="grid grid-cols-6 gap-4 mt-6">
                    {[
                      { text: "Bungalovlar", icon: "bungalovs" },
                      { text: "Villalar", icon: "villages" },
                      { text: "Çiftlik evleri", icon: "farmhouses" },
                      { text: "Kayak otelleri", icon: "ski-hotels" },
                      { text: "Lüks oteller", icon: "lux-hotels" },
                      { text: "Butik oteller", icon: "botique-hotels" },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className={`icon-box flex flex-col items-center border border-gray-200 text-sm font-medium text-color-800 
                      cursor-pointer !transition-all ${k === 0 ? "shadow-xl cursor-pointer" : "cursor-not-allowed"} px-2 py-4`}
                          style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                        >
                          <Icon
                            name={v.icon}
                            size="48"
                            color={k === 0 ? ThemeConfig.displaySettings.linkColor : "#1D2939"}
                          />
                          <span
                            className="mt-1"
                            style={
                              k === 0
                                ? {
                                    color: ThemeConfig.displaySettings.linkColor,
                                    textDecoration: ThemeConfig.displaySettings.linkType,
                                  }
                                : {
                                    textDecoration: ThemeConfig.displaySettings.linkType,
                                  }
                            }
                          >
                            {v.text}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/*Tatil Temaları*/}
              <div className="holiday-themes mt-12">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-2xl">Tatil Temaları</p>

                  <div className="relative flex items-center">
                    <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition holiday-themes-swiper-prev">
                      <Icon
                        name="arrow-left"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                    <Swiper
                      // install Swiper modules
                      className={"mt-6 pt-2 pb-12 px-3 -mx-3"}
                      modules={[Navigation, A11y]}
                      spaceBetween={15}
                      slidesPerView={4}
                      navigation={{
                        prevEl: ".holiday-themes-swiper-prev",
                        nextEl: ".holiday-themes-swiper-next",
                        disabledClass: "gray-200 pointer-events-none opacity-40",
                      }}
                    >
                      {[
                        { text: "Balayı otelleri", src: holiday1 },
                        { text: "Her şey dahil oteller", src: holiday2 },
                        { text: "Yakın bölge otelleri", src: holiday3 },
                        { text: "Denize sıfır oteller", src: holiday4 },
                        { text: "Balayı otelleri", src: holiday1 },
                        { text: "Her şey dahil oteller", src: holiday2 },
                        { text: "Yakın bölge otelleri", src: holiday3 },
                        { text: "Denize sıfır oteller", src: holiday4 },
                      ].map((v, k) => {
                        return (
                          <SwiperSlide key={k}>
                            <div
                              key={k}
                              className={`image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-sm 
                          font-medium text-color-800 cursor-pointer transition-all duration-500 hover:shadow-xl cursor-not-allowed`}
                              style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                            >
                              <div className="image-wrapper overflow-hidden">
                                <img
                                  className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                                  src={v.src}
                                  alt=""
                                />
                              </div>
                              <span
                                className="p-4"
                                style={
                                  k === 0
                                    ? {
                                        color: ThemeConfig.displaySettings.linkColor,
                                        textDecoration: ThemeConfig.displaySettings.linkType,
                                      }
                                    : {
                                        textDecoration: ThemeConfig.displaySettings.linkType,
                                      }
                                }
                              >
                                {v.text}
                              </span>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition holiday-themes-swiper-next">
                      <Icon
                        name="arrow-right"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Popüler bölgeler*/}
              <div className="popular-places mt-12">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-2xl">Popüler bölgeler</p>

                  <div className="relative flex items-center">
                    <div className="icon-area absolute right-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition popular-places-swiper-prev">
                      <Icon
                        name="arrow-left"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                    <Swiper
                      // install Swiper modules
                      className={"mt-6 pt-2 pb-12 px-3 -mx-3"}
                      modules={[Navigation, A11y]}
                      spaceBetween={15}
                      slidesPerView={3}
                      navigation={{
                        prevEl: ".popular-places-swiper-prev",
                        nextEl: ".popular-places-swiper-next",
                        disabledClass: "gray-200 pointer-events-none opacity-40",
                      }}
                    >
                      {[
                        {
                          text: "Antalya otelleri",
                          desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                          src: popular_area1,
                        },
                        {
                          text: "Belek otelleri",
                          desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                          src: popular_area2,
                        },
                        {
                          text: "Bodrum otelleri",
                          desc: "En güzel tatil Bodrum’da yapılır.",
                          src: popular_area3,
                        },
                        {
                          text: "Antalya otelleri",
                          desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                          src: popular_area1,
                        },
                        {
                          text: "Belek otelleri",
                          desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                          src: popular_area2,
                        },
                        {
                          text: "Bodrum otelleri",
                          desc: "En güzel tatil Bodrum’da yapılır.",
                          src: popular_area3,
                        },
                      ].map((v, k) => {
                        return (
                          <SwiperSlide key={k}>
                            <div
                              key={k}
                              className={`image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-base 
                          font-semibold text-color-800 cursor-pointer transition-all duration-500 hover:shadow-xl cursor-not-allowed`}
                              style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                            >
                              <div className="imaga-wrapper overflow-hidden">
                                <img
                                  className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                                  src={v.src}
                                  alt=""
                                />
                              </div>
                              <div className="p-4">
                                <p
                                  style={
                                    k === 0
                                      ? {
                                          color: ThemeConfig.displaySettings.linkColor,
                                          textDecoration: ThemeConfig.displaySettings.linkType,
                                        }
                                      : {
                                          textDecoration: ThemeConfig.displaySettings.linkType,
                                        }
                                  }
                                >
                                  {v.text}
                                </p>
                                <p className="text-gray-500 text-sm font-medium mt-0.5">{v.desc}</p>
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <div className="icon-area absolute left-[calc(100%_+_28px)] flex border border-gray-300 rounded-lg p-[3px] shadow-buttonSm hover:shadow-buttonActive transition popular-places-swiper-next">
                      <Icon
                        name="arrow-right"
                        size={"24"}
                        color={"#667085"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*Popüler bölgeler*/}
              <div className="seo-text text-sm text-gray-500 my-12">
                <div className="lp-container">
                  <p>
                    Jolly ile unutulmaz bir tatil deneyimi sizleri bekliyor. Yurt içi ve yurt dışı yüzlerce farklı konaklama
                    seçeneği ve geniş kapsamlı turlar ile uygun fiyatlı tatil paketlerini en ince ayrıntısına kadar sizi düşünerek
                    hazırlayan Jolly, 35 yıllık deneyimi ve birikimi ile hayalinizdeki tatili gerçeğe dönüştürüyor.
                    <br />
                    <br />
                    Tatilcilerin yol arkadaşı ve gezginlerin daimi başlangıç noktası olan Jolly ile harika bir yaz geçirebilir,
                    muhteşem bir balayı yapabilir; maviliklere yelken açtıran gemi turları,keşiflerle dolu yurt dışı turları,
                    Anadolu'yu gezdiren kültür turları ile her bütçeye ve zevke uygun tur seçenekleri arasından seçim yaparak
                    içinizdeki keşfetme tutkusunun peşine düşebilirsiniz.
                    <br />
                    <br />
                    Tercih sizden, popüler tatil yerleri Jolly'den... Binlerce farklı destinasyonda dilediğiniz tatile çıkabilir,
                    size uygun otel seçenekleri arasından tercih yapabilir ve seyahat planınızı sorunsuz bir şekilde
                    gerçekleştirebilirsiniz.
                    <br />
                    <br />
                    Yurt içi tatiliniz için yazın vazgeçilmez rotaları arasında yer alan Ege ve Akdeniz sahillerine uzanabilir;
                    Çeşme, Bodrum, Fethiye ve Antalya’nın enfes kumsallarından berrak sularına doğru bir yolculuğa çıkabilirsiniz.
                    Temiz havaya doymak ve yeşilin binbir tonuna şahit olmak için Karadeniz turları ile doğa tatili yapabilir,
                    günübirlik veya hafta sonu turları ile hayata kısa bir mola verebilir, birbirinden lezzetli yöresel yemeklerin
                    tadına GAP turları ile bakabilirsiniz.
                    <br />
                    <br />
                    Gözde destinasyonlardan biri olan Akdeniz'in cennet adası Kıbrıs'ta ise eşsiz bir denizi, benzersiz koyları,
                    tarihi ve kültürel öneme sahip durakları ziyaret edebilir, dört mevsim eğlenceyi bir arada bulabilir; muhteşem
                    Kıbrıs otellerinde ihtiyacınıza uygun oda seçeneklerinde erken rezervasyon fırsatlarından yararlanarak
                    unutulmaz bir tatil deneyimi yaşayabilirsiniz.
                    <br />
                    <br />
                    Tatilinizi yurt dışında geçirmek isterseniz Jolly'nin zengin içerikli gezi programları, onlarca farklı yurt
                    dışı tatil rotaları,uçaklı ya da otobüslü ulaşım imkanları, ekonomik ve vizesiz yurt dışı tur seçenekleri,
                    deneyimli rehberleri, vize işlemleri, rezervasyon kolaylığı, transfer hizmetleri ve her bütçeye uygun yurt
                    dışı otel seçenekleri ile kusursuz bir tatil yapabilirsiniz. Dünyayı keşfetmek için Avrupa'nın birbirinden
                    güzel kentlerinden Asya'nın egzotik adalarına uzanabilir, Uzak Doğu'nun gizemli tapınaklarından Afrika'nın
                    vahşi hayatına uzanan benzersiz bir maceraya atılabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <footer className="lp-footer text-xs text-gray-500 border-t border-gray-200">
              <div className="lp-container grid grid-cols-4 gap-4 py-8">
                <div className="row">
                  <div className="footer-logo-wrapper max-w-[140px]">
                    <img
                      className="object-cover max-h-[90px]"
                      src={ThemeLogo}
                      alt={ThemeConfig?.logoValues?.name}
                    />
                  </div>
                </div>
                <div className="row">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Kurumsal</li>
                    {["Hakkımızda", "İletişim", "TÜRSAB Belge No: 286428"].map((v, k) => (
                      <li
                        key={k}
                        className="mt-2"
                      >
                        {v}
                      </li>
                    ))}
                    <li className="mt-2">
                      <img
                        src={tursabImg}
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
                <div className="row">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Sözleşmeler</li>
                    {[
                      "Kullanım sözleşmesi",
                      "Gizlilik sözleşmesi",
                      "Bilgi güvenliği politikası",
                      "KVKK aydınlatma metni",
                      "KVKK işlenmesi politikası",
                      "Çerez politikası",
                    ].map((v, k) => (
                      <li
                        key={k}
                        className="mt-2"
                      >
                        {v}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="row">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Ödeme seçenekleri</li>
                    <li className="flex items-center mt-2">
                      {[visa, maestro, master, stripe].map((v, k) => {
                        return (
                          <span
                            key={k}
                            className="w-12 h-8 flex items-center justify-center bg-gray-100 rounded-lg ml-1 first:ml-0"
                          >
                            <img
                              src={v}
                              alt=""
                            />
                          </span>
                        )
                      })}
                    </li>
                    <li className="mt-4">Sitemizde görmüş olduğunuz fiyatlara KDV dahildir.</li>
                    <li className="mt-4">
                      <button className="btn bg-gray-100 text-success-500 border-none text-xs py-2 px-3 h-auto font-normal hover:bg-gray-200">
                        <Icon
                          name="shield"
                          size="16"
                          color="#12B76A"
                        />
                        <span>Güvenli alışveriş</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lp-container py-8 text-xs text-gray-400 text-center border-t border-gray-200">
                <p>
                  Bu sitenin tüm seyahat hizmetleri Gordion Teknoloji A.Ş. tarafından verilmektedir. Her hakkı saklıdır. ©2022
                </p>
              </div>
            </footer>
          </>
        )}

        {Device.id === 0 && (
          <>
            <div className="flex items-center pt-6 pb-4 pl-9 pr-3">
              <span className="font-bold">{moment(new Date()).format("HH:mm")}</span>
              <img
                className="ml-auto"
                src={stack}
                alt=""
              />
            </div>
            <div
              ref={header_ref}
              className="lp-header relative z-20 bg-white"
            >
              <div className="main-header border-b border-gray-200 py-5 px-4">
                <div className="lp-container flex items-center justify-between">
                  <div className="logo-wrapper">
                    <img
                      className="object-cover max-w-[120px] max-h-[33px]"
                      src={ThemeLogo}
                      alt={ThemeConfig?.logoValues?.name}
                    />
                  </div>
                  <div className="navbar">
                    <ul className="flex items-center">
                      <li className="flex ml-3 first:ml-0">
                        <div className="language-selector flex items-center">
                          <img
                            className="mr-1"
                            src={TR_logo}
                            alt=""
                          />
                          <span>TR / TL</span>
                        </div>
                      </li>
                      <li className="flex ml-3 p-1 first:ml-0">
                        <Icon
                          name="help-circle"
                          size="24"
                          color="#1D2939"
                        />
                      </li>
                      <li className="flex ml-3 p-1 first:ml-0">
                        <Icon
                          name="user"
                          size="24"
                          color="#1D2939"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-header">
              <div className="lp-container">
                <nav>
                  <ul className="flex items-center snap-x text-gray-500 font-medium whitespace-nowrap overflow-auto">
                    {[
                      { text: "Otel", icon: "building" },
                      { text: "Uçak", icon: "plane" },
                      { text: "Tur", icon: "suitcase" },
                      { text: "Transfer", icon: "car" },
                      { text: "Paketle Kazan", icon: "special_days" },
                    ].map((item, key) => {
                      return (
                        <li
                          key={key}
                          className={`flex items-center justify-center text-center snap-start scroll-ml-4 py-4 px-2 ml-3 first:ml-0 ${
                            key === 0 ? " font-semibold border-b-2 min-w-[120px] cursor-pointer" : "cursor-not-allowed"
                          }`}
                          style={
                            key === 0
                              ? {
                                  color: ThemeConfig.displaySettings.linkColor,
                                  borderColor: ThemeConfig.displaySettings.linkColor,
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                              : {
                                  textDecoration: ThemeConfig.displaySettings.linkType,
                                }
                          }
                        >
                          <Icon
                            className="mr-2"
                            name={item.icon}
                            size="20"
                            color={`${key === 0 ? ThemeConfig.displaySettings.linkColor : "#667085"}`}
                          />
                          <span>{item.text}</span>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="lp-main">
              <div className="travel-planner">
                <div
                  ref={searchAreaImg_ref}
                  className={`search-area relative overflow-hidden z-10 bg-cover bg-[center_70%]
            after:absolute after:inset-0 after:z-10 after:bg-gray-800 after:opacity-30`}
                  style={{ backgroundImage: `url(${search_area})` }}
                >
                  <div className="lp-container relative z-20 py-6 px-4">
                    <div className="text-wrapper text-white w-11/12">
                      <div className="heading text-2xl font-bold drop-shadow-sm">Sıradaki konaklamanızı bulun.</div>
                      <div className="text text-base font-medium drop-shadow-sm mt-4 ">
                        Oteller, evler ve çok daha fazlası için fırsatları kaçırmayın...
                      </div>
                    </div>
                    <div
                      className="searchbar flex flex-col overflow-hidden !transition-all mt-6"
                      style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                    >
                      {[
                        { placeholder: "Nereye gidiyorsun?", icon: "search" },
                        { placeholder: "Giriş - Çıkış tarihi seçin", icon: "calendar" },
                        { placeholder: "2 misafir, 1 oda", icon: "person" },
                      ].map((v, k) => {
                        return (
                          <div
                            key={k}
                            className={`input-wrapper w-full relative shadow-buttonSm border border-gray-200 overflow-hidden !transition-all mt-3`}
                            style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                          >
                            <Icon
                              className="absolute top-1/2 left-2 transform -translate-y-1/2 pointer-events-none"
                              name={v.icon}
                              size="20"
                              color={`${k === 2 ? "#000" : "#667085"}`}
                            />
                            <input
                              type="text"
                              className={`w-full border-none !pl-9 disabled:cursor-not-allowed disabled:!bg-white disabled:!pointer-events-auto ${
                                k === 2 ? "placeholder:text-gray-800" : "placeholder:text-gray-500"
                              }`}
                              placeholder={v.placeholder}
                              disabled
                            />
                          </div>
                        )
                      })}
                      <button
                        className={`btn whitespace-nowrap px-7 border-0 cursor-not-allowed !transition-all mt-3`}
                        style={{
                          backgroundColor: ThemeConfig.displaySettings.buttonColor,
                          borderRadius: ThemeConfig.displaySettings.borderRadius,
                          color: ThemeConfig.displaySettings?.buttonColor
                            ? hexIsLight(ThemeConfig.displaySettings.buttonColor)
                              ? "black"
                              : "white"
                            : "",
                        }}
                      >
                        Otel ara
                      </button>
                    </div>
                  </div>
                </div>
                <div className="features py-6 px-4">
                  <div
                    className="lp-container flex flex-col border border-gray-200 items-center"
                    style={{
                      borderRadius: ThemeConfig.displaySettings.borderRadius,
                    }}
                  >
                    {[
                      { text: "Fiyat farkı iade garantisi", icon: "rotate-ccw" },
                      { text: "Güvenli online alışveriş", icon: "shield" },
                      { text: "18 aya varan taksitler", icon: "credit-card" },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className="features-box w-full flex items-center justify-center text-center py-4"
                        >
                          <Icon
                            className="mr-2"
                            name={v.icon}
                            size="16"
                            color="#475467"
                          />
                          <span className="font-medium text-gray-500">{v.text}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              {/*Banner Area*/}
              <div className="banner-area">
                {/*Banner Slider*/}
                <div className="mobile-banner-content">
                  <div className="lp-container swiper-area relative flex flex-col z-10 overflow-hidden !transition-all pb-3">
                    <p className="text-gray-800 font-semibold text-xl mb-2 px-4">Kampanyalar</p>
                    <Swiper
                      // install Swiper modules
                      className={"mx-0 mb-5"}
                      modules={[Pagination, A11y, Parallax]}
                      spaceBetween={12}
                      slidesPerView={"auto"}
                      speed={1200}
                      loop={true}
                      draggable={true}
                      freeMode={true}
                      centeredSlides={true}
                      pagination={{
                        el: ".mobile-banner-content .swiper-pagination",
                        clickable: true,
                        dynamicBullets: true,
                      }}
                    >
                      {[1, 2, 1, 2, 1, 2].map((v, k) => {
                        return (
                          <SwiperSlide
                            className="overflow-hidden w-10/12 transition-all"
                            style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                            key={k}
                          >
                            <img
                              className="absolute t-0 l-0 -z-10 h-full w-full object-cover transition-all"
                              src={banner1}
                              alt=""
                              style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                            />
                            <div className="text-area flex flex-col items-start w-9/12 h-[196px] p-4">
                              <p className="text-xl font-semibold text-white drop-shadow-sm">
                                Yaz fırsatları devam ediyor! Kaçırmadan yakala
                              </p>
                              <button
                                className="btn xs border-none mt-auto text-sm cursor-not-allowed"
                                style={{
                                  backgroundColor: ThemeConfig.displaySettings.buttonColor,
                                  borderRadius: ThemeConfig.displaySettings.borderRadius,
                                  color: ThemeConfig.displaySettings?.buttonColor
                                    ? hexIsLight(ThemeConfig.displaySettings.buttonColor)
                                      ? "black"
                                      : "white"
                                    : "",
                                }}
                              >
                                Fırsatları gör
                              </button>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                    <div className="swiper-pagination bottom-0 left" />
                  </div>
                </div>
              </div>

              {/*Konaklama Seçenekleri*/}
              <div className="accommodation_options mt-3 overflow-auto">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-xl px-4 mb-2">Konaklama seçenekleri</p>

                  <Swiper
                    className={"px-4"}
                    spaceBetween={12}
                    slidesPerView={"auto"}
                    speed={1200}
                    draggable={true}
                    freeMode={true}
                  >
                    {[
                      { text: "Bungalovlar", icon: "bungalovs" },
                      { text: "Villalar", icon: "villages" },
                      { text: "Çiftlik evleri", icon: "farmhouses" },
                      { text: "Kayak otelleri", icon: "ski-hotels" },
                      { text: "Lüks oteller", icon: "lux-hotels" },
                      { text: "Butik oteller", icon: "botique-hotels" },
                    ].map((v, k) => {
                      return (
                        <SwiperSlide
                          className="overflow-hidden w-auto"
                          style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                          key={k}
                        >
                          <div
                            key={k}
                            className={`icon-box flex flex-col items-center border border-gray-200 text-sm font-medium text-color-800 shrink-0 w-[106px] whitespace-nowrap
                      cursor-pointer !transition-all ${k === 0 ? "shadow-xl cursor-pointer" : "cursor-not-allowed"} px-2 py-4`}
                            style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                          >
                            <Icon
                              name={v.icon}
                              size="32"
                              color={k === 0 ? ThemeConfig.displaySettings.linkColor : "#1D2939"}
                            />
                            <span
                              className="mt-1"
                              style={
                                k === 0
                                  ? {
                                      color: ThemeConfig.displaySettings.linkColor,
                                      textDecoration: ThemeConfig.displaySettings.linkType,
                                    }
                                  : {
                                      textDecoration: ThemeConfig.displaySettings.linkType,
                                    }
                              }
                            >
                              {v.text}
                            </span>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>

              {/*Tatil Temaları*/}
              <div className="holiday-themes mt-6">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-xl mb-2 px-4">Tatil Temaları</p>

                  <div className="relative flex items-center">
                    <Swiper
                      className={"px-4"}
                      spaceBetween={12}
                      slidesPerView={"auto"}
                      speed={1200}
                      draggable={true}
                      freeMode={true}
                    >
                      {[
                        { text: "Balayı otelleri", src: holiday1 },
                        { text: "Her şey dahil oteller", src: holiday2 },
                        { text: "Yakın bölge otelleri", src: holiday3 },
                        { text: "Denize sıfır oteller", src: holiday4 },
                        { text: "Balayı otelleri", src: holiday1 },
                        { text: "Her şey dahil oteller", src: holiday2 },
                        { text: "Yakın bölge otelleri", src: holiday3 },
                        { text: "Denize sıfır oteller", src: holiday4 },
                      ].map((v, k) => {
                        return (
                          <SwiperSlide
                            className={"w-[164px]"}
                            key={k}
                          >
                            <div
                              key={k}
                              className={`image-box flex flex-col items-start border border-gray-200 bg-white overflow-hidden text-sm 
                          font-medium text-color-800 cursor-pointer transition-all duration-500 cursor-not-allowed`}
                              style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                            >
                              <div className="image-wrapper overflow-hidden">
                                <img
                                  className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                                  src={v.src}
                                  alt=""
                                />
                              </div>
                              <div
                                className="desc-area flex overflow-hidden p-4"
                                style={
                                  k === 0
                                    ? {
                                        color: ThemeConfig.displaySettings.linkColor,
                                        textDecoration: ThemeConfig.displaySettings.linkType,
                                      }
                                    : {
                                        textDecoration: ThemeConfig.displaySettings.linkType,
                                      }
                                }
                              >
                                <span className="truncate">{v.text}</span>
                              </div>
                            </div>
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </div>
              </div>

              {/*Popüler bölgeler*/}
              <div className="popular-places mt-6 px-4">
                <div className="lp-container">
                  <p className="text-gray-800 font-semibold text-xl mb-2">Popüler tatil seçenekleri</p>

                  <div className="relative flex flex-col">
                    {[
                      {
                        text: "Antalya otelleri",
                        desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                        src: popular_area1,
                      },
                      {
                        text: "Belek otelleri",
                        desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                        src: popular_area2,
                      },
                      {
                        text: "Bodrum otelleri",
                        desc: "En güzel tatil Bodrum’da yapılır.",
                        src: popular_area3,
                      },
                      {
                        text: "Antalya otelleri",
                        desc: "Akdeniz’in incisi Antalya sizi bekliyor.",
                        src: popular_area1,
                      },
                      {
                        text: "Belek otelleri",
                        desc: "Antalya’nın gözdesinde yerinizi ayırın.",
                        src: popular_area2,
                      },
                      {
                        text: "Bodrum otelleri",
                        desc: "En güzel tatil Bodrum’da yapılır.",
                        src: popular_area3,
                      },
                    ].map((v, k) => {
                      return (
                        <div
                          key={k}
                          className={`image-box flex flex-col border border-gray-200 bg-white overflow-hidden text-base 
                          font-semibold text-color-800 cursor-pointer transition-all duration-500 cursor-not-allowed mt-4 first:mt-0`}
                          style={{ borderRadius: ThemeConfig.displaySettings.borderRadius }}
                        >
                          <div className="imaga-wrapper overflow-hidden">
                            <img
                              className="w-full h-full object-cover transition-all duration-500 hover:scale-125"
                              src={v.src}
                              alt=""
                            />
                          </div>
                          <div className="p-4">
                            <p
                              style={
                                k === 0
                                  ? {
                                      color: ThemeConfig.displaySettings.linkColor,
                                      textDecoration: ThemeConfig.displaySettings.linkType,
                                    }
                                  : {
                                      textDecoration: ThemeConfig.displaySettings.linkType,
                                    }
                              }
                            >
                              {v.text}
                            </p>
                            <p className="text-gray-500 text-sm font-medium mt-0.5">{v.desc}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {/*Popüler bölgeler*/}
              <div className="seo-text text-sm text-gray-500 mt-6 px-4">
                <div className="lp-container">
                  <p>
                    Jolly ile unutulmaz bir tatil deneyimi sizleri bekliyor. Yurt içi ve yurt dışı yüzlerce farklı konaklama
                    seçeneği ve geniş kapsamlı turlar ile uygun fiyatlı tatil paketlerini en ince ayrıntısına kadar sizi düşünerek
                    hazırlayan Jolly, 35 yıllık deneyimi ve birikimi ile hayalinizdeki tatili gerçeğe dönüştürüyor.
                    <br />
                    <br />
                    Tatilcilerin yol arkadaşı ve gezginlerin daimi başlangıç noktası olan Jolly ile harika bir yaz geçirebilir,
                    muhteşem bir balayı yapabilir; maviliklere yelken açtıran gemi turları,keşiflerle dolu yurt dışı turları,
                    Anadolu'yu gezdiren kültür turları ile her bütçeye ve zevke uygun tur seçenekleri arasından seçim yaparak
                    içinizdeki keşfetme tutkusunun peşine düşebilirsiniz.
                    <br />
                    <br />
                    Tercih sizden, popüler tatil yerleri Jolly'den... Binlerce farklı destinasyonda dilediğiniz tatile çıkabilir,
                    size uygun otel seçenekleri arasından tercih yapabilir ve seyahat planınızı sorunsuz bir şekilde
                    gerçekleştirebilirsiniz.
                    <br />
                    <br />
                    Yurt içi tatiliniz için yazın vazgeçilmez rotaları arasında yer alan Ege ve Akdeniz sahillerine uzanabilir;
                    Çeşme, Bodrum, Fethiye ve Antalya’nın enfes kumsallarından berrak sularına doğru bir yolculuğa çıkabilirsiniz.
                    Temiz havaya doymak ve yeşilin binbir tonuna şahit olmak için Karadeniz turları ile doğa tatili yapabilir,
                    günübirlik veya hafta sonu turları ile hayata kısa bir mola verebilir, birbirinden lezzetli yöresel yemeklerin
                    tadına GAP turları ile bakabilirsiniz.
                    <br />
                    <br />
                    Gözde destinasyonlardan biri olan Akdeniz'in cennet adası Kıbrıs'ta ise eşsiz bir denizi, benzersiz koyları,
                    tarihi ve kültürel öneme sahip durakları ziyaret edebilir, dört mevsim eğlenceyi bir arada bulabilir; muhteşem
                    Kıbrıs otellerinde ihtiyacınıza uygun oda seçeneklerinde erken rezervasyon fırsatlarından yararlanarak
                    unutulmaz bir tatil deneyimi yaşayabilirsiniz.
                    <br />
                    <br />
                    Tatilinizi yurt dışında geçirmek isterseniz Jolly'nin zengin içerikli gezi programları, onlarca farklı yurt
                    dışı tatil rotaları,uçaklı ya da otobüslü ulaşım imkanları, ekonomik ve vizesiz yurt dışı tur seçenekleri,
                    deneyimli rehberleri, vize işlemleri, rezervasyon kolaylığı, transfer hizmetleri ve her bütçeye uygun yurt
                    dışı otel seçenekleri ile kusursuz bir tatil yapabilirsiniz. Dünyayı keşfetmek için Avrupa'nın birbirinden
                    güzel kentlerinden Asya'nın egzotik adalarına uzanabilir, Uzak Doğu'nun gizemli tapınaklarından Afrika'nın
                    vahşi hayatına uzanan benzersiz bir maceraya atılabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <footer className="lp-footer text-xs text-gray-500 border-t border-gray-200 px-4 mt-6">
              <div className="lp-container flex flex-col py-8">
                <div className="row mt-4 first:mt-0">
                  <div className="footer-logo-wrapper max-w-[140px]">
                    <img
                      className="object-cover max-h-[90px]"
                      src={ThemeLogo}
                      alt={ThemeConfig?.logoValues?.name}
                    />
                  </div>
                </div>
                <div className="row mt-4 first:mt-0">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Kurumsal</li>
                    {["Hakkımızda", "İletişim", "TÜRSAB Belge No: 286428"].map((v, k) => (
                      <li
                        key={k}
                        className="mt-2"
                      >
                        {v}
                      </li>
                    ))}
                    <li className="mt-2">
                      <img
                        src={tursabImg}
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
                <div className="row mt-4 first:mt-0">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Sözleşmeler</li>
                    {[
                      "Kullanım sözleşmesi",
                      "Gizlilik sözleşmesi",
                      "Bilgi güvenliği politikası",
                      "KVKK aydınlatma metni",
                      "KVKK işlenmesi politikası",
                      "Çerez politikası",
                    ].map((v, k) => (
                      <li
                        key={k}
                        className="mt-2"
                      >
                        {v}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="row mt-4 first:mt-0">
                  <ul>
                    <li className="font-medium text-sm text-gray-800 pb-2">Ödeme seçenekleri</li>
                    <li className="flex items-center mt-2">
                      {[visa, maestro, master, stripe].map((v, k) => {
                        return (
                          <span
                            key={k}
                            className="w-12 h-8 flex items-center justify-center bg-gray-100 rounded-lg ml-1 first:ml-0"
                          >
                            <img
                              src={v}
                              alt=""
                            />
                          </span>
                        )
                      })}
                    </li>
                    <li className="mt-4">Sitemizde görmüş olduğunuz fiyatlara KDV dahildir.</li>
                    <li className="mt-4">
                      <button className="btn bg-gray-100 text-success-500 border-none text-xs py-2 px-3 h-auto font-normal hover:bg-gray-200">
                        <Icon
                          name="shield"
                          size="16"
                          color="#12B76A"
                        />
                        <span>Güvenli alışveriş</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lp-container py-8 text-xs text-gray-400 text-center border-t border-gray-200">
                <p>
                  Bu sitenin tüm seyahat hizmetleri Gordion Teknoloji A.Ş. tarafından verilmektedir. Her hakkı saklıdır. ©2022
                </p>
              </div>
            </footer>
          </>
        )}
      </div>
    </>
  )
}

export default LivePreview
