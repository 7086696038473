import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

// components
import { TitleH3 } from "components"
import Icon from "./Icon"

// img
import uploadCircle from "../assets/svg/upload-circle.svg"
import { sizeCalculator } from "../utils/sizeCalculator";

const LogoUpload = (props) => {
  let {
    accept,
    description,
    title,
    titleValue = "Marka Logosu",
    config: { ThemeConfig },
    setConfig,
    hasTitle = true,
    tursabLogo,
    hasHotelWidget = false,
    setTursabLogo,
    favIcon = false,
  } = props

  // refs
  const formRef = useRef()
  const UploadFileRef = useRef()

  // states
  const [LTDraggableImageBoxHoverActive, setLTDraggableImageBoxHoverActive] = useState(false)

  const displayLogo =
    ThemeConfig?.favIconValues?.src ||
    ThemeConfig?.displaySettings?.logoValues?.src ||
    `https://gordiosdatatest.blob.core.windows.net/files/${tursabLogo?.value}`

  // Func
  const readImage = (file) => {
    const reader = new FileReader()
    reader.addEventListener("load", (event) => {
      if (favIcon) {
        setConfig({
          favIconValues: {
            src: event.target.result,
            size: file.size,
            name: file.name,
            fileForBinary: file,
          },
        })
      } else {
        setConfig({
          logoValues: {
            src: event.target.result,
            size: file.size,
            name: file.name,
            fileForBinary: file,
          },
        })
      }
    })
    reader.readAsDataURL(file)
    setLTDraggableImageBoxHoverActive(false)
    UploadFileRef.current.value = ""
  }

  const imageDragging = (event, show) => {
    event.stopPropagation()
    event.preventDefault()
    event.dataTransfer.dropEffect = "copy"
    show ? setLTDraggableImageBoxHoverActive(true) : setLTDraggableImageBoxHoverActive(false)
  }

  const imageDropped = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const fileList = event.dataTransfer.files
    readImage(fileList[0])
  }

  return (
    <>
      <div className="ds-box mb-8">
        {title && <TitleH3 className="mb-2">{titleValue}</TitleH3>}

        <div className="box-content relative">
          {/*<div className="animate-pulse absolute left-0 w-full h-full bottom-0 border border-blue-500 border-dashed rounded-lg overflow-hidden">
                <div className="grid grid-cols-4 h-full">
                  <div className="h-full bg-slate-200 rounded col-span-4" />
                </div>
              </div>*/}
          <div className="data-box">
            <form
              ref={formRef}
              className="hidden"
            >
              <input
                type="file"
                ref={UploadFileRef}
                onChange={(e) => readImage(UploadFileRef.current.files[0])}
                accept={accept}
              />
            </form>
            <div className="flex justify-center items-center w-full mb-8">
              {ThemeConfig?.displaySettings?.logoValues || tursabLogo?.value || ThemeConfig?.favIconValues ? (
                <div className="w-full">
                  <div
                    className={`${
                      LTDraggableImageBoxHoverActive ? "bg-white " : ""
                    }w-full flex items-center justify-center min-h-[100px] border border-gray-200 border-dashed rounded-lg mb-4`}
                    onDragOver={(e) => imageDragging(e, true)}
                    onDragLeave={(e) => imageDragging(e, false)}
                    onDrop={imageDropped}
                  >
                    <img
                      src={displayLogo}
                      alt="Logo"
                      className={`${LTDraggableImageBoxHoverActive ? "opacity-0 " : ""}max-h-60`}
                    />
                  </div>

                  <div className="flex gap-2 items-center relative text-sm font-medium border border-blue-500 rounded-lg pl-3 py-4 pr-10 shadow-md">
                    <img
                      src={uploadCircle}
                      alt="Upload icon"
                    />

                    <div className="flex flex-col overflow-hidden">
                      <span className="text-gray-700 text-sm truncate">
                        {favIcon
                          ? "favicon"
                          : tursabLogo?.value !== "" && hasTitle && !hasHotelWidget
                          ? "Tursab_belge"
                          : ThemeConfig.displaySettings?.logoValues?.name}
                      </span>
                      <span className="text-gray-500 text-sm">
                        {tursabLogo?.value !== "" ? "" : sizeCalculator(ThemeConfig.displaySettings?.logoValues?.size)}
                      </span>
                    </div>

                    <div className="absolute h-5 w-5 right-4 top-4">
                      <button
                        onClick={() => {
                          if (favIcon) {
                            setConfig({ favIconValues: null })
                          } else {
                            setConfig({
                              ...ThemeConfig.displaySettings,
                              logoValues: null,
                            })
                          }

                          if (tursabLogo !== undefined) {
                            setTursabLogo({
                              ...tursabLogo,
                              value: null,
                            })
                          }
                        }}
                      >
                        <Icon
                          name="close"
                          color="black"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${
                    LTDraggableImageBoxHoverActive ? "border-blue-300 " : "border-gray-300 "
                  }flex flex-col justify-center items-center w-full bg-white rounded-lg border border-dashed cursor-pointer transition hover:bg-gray-100`}
                >
                  <div className="upload-area whitespace-normal overflow-hidden">
                    <button
                      onClick={() => UploadFileRef.current.click()}
                      onDragOver={(e) => imageDragging(e, true)}
                      onDragLeave={(e) => imageDragging(e, false)}
                      onDrop={imageDropped}
                    >
                      <div className="flex flex-col justify-center items-center py-4 px-6">
                        <img
                          src={uploadCircle}
                          alt="Upload icon"
                        />

                        <p
                          className={`${
                            LTDraggableImageBoxHoverActive ? "text-blue-500 " : "text-gray-500 "
                          }text-sm text-center mt-3`}
                        >
                          <span className="font-normal">
                            Yüklemek için <span className="font-medium text-blue-500">tıklayın</span> ya da sürükleyip bırakın.
                          </span>
                          <span className="text-xs">
                            <br />
                            <br />
                            {description}
                          </span>
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogoUpload

LogoUpload.propTypes = {
  accept: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.bool,
}
LogoUpload.defaultProps = {
  accept: ".png, .svg, .gif",
  title: true,
  description: "(maks. 2 MB) SVG, PNG, ya da GIF formatında, zemini olmayan (transparan) bir logo yüklenmelidir.",
}
