import {ReactSVG} from "react-svg";

const ImagePreview = ({src, ...props}) => {
	if (!src) return null;
	const getImageExtension = () => {
		return src.split(".").pop();
	};

	const extension = getImageExtension();

	if (extension === "svg") {
		return <ReactSVG src={src}/>;
	}

	return <img src={src} {...props} />
}

export default ImagePreview