import TokenCookieHelper from "helpers/tokenCookieHelper";
// cookies
import Cookies from "universal-cookie"
const cookies = new Cookies()

export const logout = () => {
  cookies.remove("associatedDomain")
  cookies.remove("userInfo")
  if (TokenCookieHelper.deleteTokenCookie()) {
    window.location.href = `${process.env.REACT_APP_AUTH_DOMAIN}/connect/logout/?post_logout_redirect_uri=${encodeURIComponent(
      window.location.origin,
    )}`
  } else {
    logout()
  }
}
