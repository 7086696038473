import { call, fork, put, takeLatest } from "redux-saga/effects"
import * as actions from "redux/actions/accountManagement"
import {
  GET_ALL_TENANTS_REQUESTED,
  GET_TENANT_REQUESTED,
  SET_TENANT_REQUESTED,
  UPDATE_TENANT_REQUESTED,
  DELETE_TENANT_REQUESTED,
  GET_DOMAIN_REQUESTED,
  SET_DOMAIN_REQUESTED,
  DELETE_DOMAIN_REQUESTED,
  GET_USERLIST_REQUESTED,
  SET_USERLIST_REQUESTED,
} from "redux/types"
import { API_DELETE, API_GET, API_POST } from "helpers/api"

function* watchMyAccountManagement() {
  yield takeLatest(GET_ALL_TENANTS_REQUESTED, getAllTenants)
  yield takeLatest(GET_TENANT_REQUESTED, getTenant)
  yield takeLatest(SET_TENANT_REQUESTED, setTenant)
  yield takeLatest(UPDATE_TENANT_REQUESTED, updateTenant)
  yield takeLatest(DELETE_TENANT_REQUESTED, deleteTenant)

  yield takeLatest(GET_DOMAIN_REQUESTED, getDomain)
  yield takeLatest(SET_DOMAIN_REQUESTED, setDomain)
  yield takeLatest(DELETE_DOMAIN_REQUESTED, deleteDomain)

  yield takeLatest(GET_USERLIST_REQUESTED, getUserList)
  yield takeLatest(SET_USERLIST_REQUESTED, setUserList)
}

function* getAllTenants(action) {
  try {
    const response = yield call(apiGetAllTenants, action?.payload)
    const {
      data: { Data, RecordsTotal, RecordsFiltered },
    } = response
    yield put(actions.getAllTenantsSucceed({
      table: Data,
      RecordsFiltered,
      RecordsTotal,
    }))
  } catch (err) {
    yield put(actions.getAllTenantsFailed())
  }
}
function* getTenant(action) {
  try {
    const response = yield call(apiGetTenant, action.payload)
    const {
      data: { Result },
    } = response

    yield put(actions.getTenantSucceed(Result))
  } catch (err) {
    yield put(actions.getTenantFailed())
  }
}
function* setTenant(action) {
  try {
    const response = yield call(apiSetTenant, action.payload)
    const {
      data: { result },
    } = response

    yield put(actions.setTenantSucceed(result))
  } catch (err) {
    yield put(actions.setTenantFailed())
  }
}
function* updateTenant(action) {
  try {
    const response = yield call(apiUpdateTenant, action.payload)
    const {
      data: { Result },
    } = response

    if (!Result) {
      let result = response?.data?.Errors[0]?.Message
      yield put(actions.updateTenantSucceed(result))
      throw result
    }
    yield put(actions.updateTenantSucceed(Result))
  } catch (err) {
    yield put(actions.updateTenantFailed())
  }
}
function* deleteTenant(action) {
  try {
    const response = yield call(apiDeleteTenant, action.payload)
    const {
      data: { Result },
    } = response

    yield put(actions.deleteTenantSucceed(Result))
  } catch (err) {
    yield put(actions.deleteTenantFailed())
  }
}

function* getDomain(action) {
  try {
    const response = yield call(apiGetDomain, action.payload)
    const { data } = response
    yield put(actions.getDomainSucceed(data))
  } catch (err) {
    yield put(actions.getDomainFailed())
  }
}
function* setDomain(action) {
  try {
    const response = yield call(apiSetDomain, action.payload)
    const { data } = response

    yield put(actions.setDomainSucceed(data))
  } catch (err) {
    yield put(actions.setDomainFailed())
  }
}
function* deleteDomain(action) {
  try {
    const response = yield call(apiDeleteDomain, action.payload)
    const { data } = response

    yield put(actions.deleteDomainSucceed(data))
  } catch (err) {
    yield put(actions.deleteDomainFailed())
  }
}

function* getUserList(action) {
  try {
    const response = yield call(apiGetUserList, action.payload)
    const { data } = response
    yield put(actions.getUserListSucceed(data))
  } catch (err) {
    yield put(actions.getUserListFailed())
  }
}
function* setUserList(action) {
  try {
    const response = yield call(apiSetUserList, action.payload)
    const { data } = response
    yield put(actions.setUserListSucceed(data))
  } catch (err) {
    yield put(actions.setUserListFailed())
  }
}

const apiGetAllTenants = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/tenant/list`, payload)
}
const apiGetTenant = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/tenant/get/${payload}`)
}
const apiSetTenant = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/tenant/save`, payload)
}
const apiUpdateTenant = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/tenant/update`, payload)
}
const apiDeleteTenant = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/tenant/delete/${payload}`)
}

const apiGetDomain = (payload) => {
  return API_GET(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/list/${payload}`)
}
const apiSetDomain = (payload) => {
  return API_POST(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/save`, payload)
}
const apiDeleteDomain = (payload) => {
  return API_DELETE(`${process.env.REACT_APP_DISPLAY_DOMAIN}/api/domain/delete/${payload}`)
}

const apiGetUserList = (payload) => {
  return API_GET(`${process.env.REACT_APP_AUTH_DOMAIN}/api/account/list/${payload}`, {}, false)
}
const apiSetUserList = (payload) => {
  return API_POST(`${process.env.REACT_APP_AUTH_DOMAIN}/api/account/register`, payload, "application/json", false)
}
export default function* myReservationsList() {
  yield fork(watchMyAccountManagement)
}
